import React, { createContext, useMemo } from "react";
import { SettingsContext } from "./SettingsContext";
import { useTranslation } from "react-i18next";

export const PersonalizeContext = createContext();

export const PersonalizeProvider = ({ children }) => {
  const { t } = useTranslation("global");
  const { updateStyle, getStyles } = React.useContext(SettingsContext);
  const [stylesFetched, setStylesFetched] = React.useState(false);

  const [showContent, setShowContent] = React.useState("");
  const [personalizeActivated, setPersonalizeActivated] = React.useState(null);
  const [backgroundColor, setBackgroundColor] = React.useState(null);
  const [backgroundImageChanged, setBackgroundImageChanged] =
    React.useState(false);
  const [backgroundImageFetched, setBackgroundImageFetched] =
    React.useState(null);
  const [backgroundImage, setBackgroundImage] = React.useState(null);
  const [ourBackgroundImage, setOurBackgroundImage] = React.useState(null);
  const [backgroundImageOpacity, setBackgroundImageOpacity] =
    React.useState(null);
  const [buttonsColor, setButtonsColor] = React.useState(null);
  const [linesColor, setLinesColor] = React.useState(null);
  const [iconsColor, setIconsColor] = React.useState(null);
  const [menuButtonSize, setMenuButtonSize] = React.useState(null);
  const [menuButtonAlign, setMenuButtonAlign] = React.useState(null);
  const [menuButtonContentAlign, setMenuButtonContentAlign] =
    React.useState(null);
  const [textsColor, setTextsColor] = React.useState(null);
  const [secondaryTextsColor, setSecondaryTextsColor] = React.useState(null);
  const [titlesFontSize, setTitlesFontSize] = React.useState(null);
  const [textsFontSize, setTextsFontSize] = React.useState(null);
  const [categoriesFontSize, setCategoriesFontSize] = React.useState(null);
  const [importantTextsFontSize, setImportantTextsFontSize] =
    React.useState(null);
  const [informationText, setInformationText] = React.useState("Informations");
  const [informationSubtitleText, setInformationSubtitleText] = React.useState(
    "Téléphone, adresse et réseaux sociaux"
  );
  const [informationsLinesColor, setInformationsLinesColor] =
    React.useState(null);
  const [informationCopiedTextColor, setInformationCopiedTextColor] =
    React.useState(null);
  const [reviewButtonTextColor, setReviewButtonTextColor] =
    React.useState(null);
  const [reviewButtonText, setReviewButtonText] = React.useState(null);
  const [reviewPageTitleText, setReviewPageTitleText] = React.useState(null);
  const [reviewFeedbackText, setReviewFeedbackText] = React.useState(null);
  const [reviewCloseButtonText, setReviewCloseButtonText] =
    React.useState(null);
  const [reviewSendButtonText, setReviewSendButtonText] = React.useState(null);
  const [reviewButtonColor, setReviewButtonColor] = React.useState(null);
  const [activeStarColor, setActiveStarColor] = React.useState(null);
  const [promotionalBackgroundColor, setPromotionalBackgroundColor] =
    React.useState(null);
  const [promotionalTextColor, setPromotionalTextColor] = React.useState(null);
  const [promotionalTextSize, setPromotionalTextSize] = React.useState(null);
  const [promotionalBorderColor, setPromotionalBorderColor] =
    React.useState(null);
  const [allergensTitleFontSize, setAllergensTitleFontSize] =
    React.useState(null);
  const [allergensTitleColor, setAllergensTitleColor] = React.useState(null);
  const [allergensTextFontSize, setAllergensTextFontSize] =
    React.useState(null);
  const [allergensTextColor, setAllergensTextColor] = React.useState(null);

  const handleStyles = async () => {
    const styles = await getStyles();
    if (!styles) {
      console.log("[handleStyles] no styles");
      setPersonalizeActivated(false);
      setBackgroundColor("#ffffff");
      setBackgroundImage(null);
      setBackgroundImageOpacity(0.5);
      setButtonsColor("#f3f3f3");
      setLinesColor("#f3f3f3");
      setIconsColor("black");
      setMenuButtonSize("LARGE");
      setMenuButtonAlign("LEFT");
      setMenuButtonContentAlign("LEFT");
      setTextsColor("black");
      setSecondaryTextsColor("#757575");
      setTitlesFontSize(28);
      setTextsFontSize(16);
      setCategoriesFontSize(24);
      setImportantTextsFontSize(18);
      setInformationText("Informations");
      setInformationSubtitleText("Téléphone, adresse et réseaux sociaux");
      setInformationsLinesColor("#f3f3f3");
      setInformationCopiedTextColor("green");
      setReviewButtonTextColor("white");
      setReviewButtonColor("#000000");
      setActiveStarColor("#ffd700");
      setReviewButtonText("Noter l'établissement");
      setReviewPageTitleText("Noter l'établissement");
      setReviewFeedbackText("Merci, votre avis est important pour nous !");
      setReviewCloseButtonText("Fermer");
      setReviewSendButtonText("Envoyer");
      setPromotionalBackgroundColor("rgb(234, 242, 234)");
      setPromotionalTextColor("black");
      setPromotionalTextSize(16);
      setPromotionalBorderColor("rgb(11, 161, 21)");
      setStylesFetched(true);
      setAllergensTitleFontSize(18);
      setAllergensTitleColor("black");
      setAllergensTextFontSize(16);
      setAllergensTextColor("#757575");
      setBackgroundImageChanged(false);
    } else {
      console.log("[handleStyles] styles", styles);
      setPersonalizeActivated(styles.custom || false);
      setBackgroundColor(styles.backgroundColor || "#ffffff");
      setBackgroundImage(styles.backgroundImage || null);
      setBackgroundImageFetched(styles.backgroundImage || null);
      setBackgroundImageOpacity(styles.backgroundImageOpacity || 0.5);
      setButtonsColor(styles.buttonsColor || "#f3f3f3");
      setLinesColor(styles.linesColor || "#f3f3f3");
      setIconsColor(styles.iconsColor || "black");
      setMenuButtonSize(styles.menuButtonSize || "LARGE");
      setMenuButtonAlign(styles.menuButtonAlign || "LEFT");
      setMenuButtonContentAlign(styles.menuButtonContentAlign || "LEFT");
      setTextsColor(styles.textsColor || "black");
      setSecondaryTextsColor(styles.secondaryTextsColor || "#757575");
      setTitlesFontSize(styles.titlesFontSize || 28);
      setTextsFontSize(styles.textsFontSize || 16);
      setCategoriesFontSize(styles.categoriesFontSize || 24);
      setImportantTextsFontSize(styles.importantTextsFontSize || 18);
      setInformationText(styles.informationText || "Informations");
      setInformationSubtitleText(
        styles.informationSubtitleText ||
          "Téléphone, adresse et réseaux sociaux"
      );
      setInformationsLinesColor("#f3f3f3");
      setInformationCopiedTextColor(
        styles.informationCopiedTextColor || "green"
      );
      setReviewButtonTextColor(styles.reviewButtonTextColor || "white");
      setReviewButtonColor(styles.reviewButtonColor || "#000000");
      setReviewButtonText(styles.reviewButtonText || "Noter l'établissement");
      setReviewPageTitleText(
        styles.reviewPageTitleText || "Noter l'établissement"
      );
      setReviewFeedbackText(
        styles.reviewFeedbackText ||
          "Merci, votre avis est important pour nous !"
      );
      setReviewCloseButtonText(styles.reviewCloseButtonText || "Fermer");
      setReviewSendButtonText(styles.reviewSendButtonText || "Envoyer");
      setActiveStarColor(styles.activeStarColor || "#ffd700");
      setPromotionalBackgroundColor(
        styles.promotionalBackgroundColor || "rgb(234, 242, 234)"
      );
      setPromotionalTextColor(styles.promotionalTextColor || "black");
      setPromotionalTextSize(styles.promotionalTextSize || 16);
      setPromotionalBorderColor(
        styles.promotionalBorderColor || "rgb(11, 161, 21)"
      );
      setAllergensTitleFontSize(styles.allergensTitleFontSize || 18);
      setAllergensTitleColor(styles.allergensTitleColor || "black");
      setAllergensTextFontSize(styles.allergensTextFontSize || 16);
      setAllergensTextColor(styles.allergensTextColor || "#757575");
      setStylesFetched(true);
      setBackgroundImageChanged(false);
    }
  };

  React.useEffect(() => {
    handleStyles();
  }, []);

  const handleChangePersonalizeActivation = (event) => {
    setPersonalizeActivated(event.target.checked);
  };

  const handleShowContent = (title) => {
    setShowContent(title);
  };

  React.useEffect(() => {
    if (stylesFetched && backgroundImage !== backgroundImageFetched) {
      setBackgroundImageChanged(true);
    }
  }, [backgroundImage, ourBackgroundImage]);

  React.useEffect(() => {
    console.log("backgroundImageChanged", backgroundImageChanged);
  }, [backgroundImageChanged]);

  const handleSaveStyle = async () => {
    await updateStyle(
      {
        custom: personalizeActivated,
        backgroundColor,
        backgroundImage,
        ourBackgroundImage,
        backgroundImageOpacity,
        buttonsColor,
        linesColor,
        iconsColor,
        menuButtonSize,
        menuButtonAlign,
        menuButtonContentAlign,
        textsColor,
        secondaryTextsColor,
        titlesFontSize: titlesFontSize,
        textsFontSize: textsFontSize,
        categoriesFontSize: categoriesFontSize,
        importantTextsFontSize: importantTextsFontSize,
        informationText,
        informationSubtitleText,
        informationsLinesColor,
        informationCopiedTextColor,
        reviewButtonTextColor,
        reviewButtonText,
        reviewPageTitleText,
        reviewFeedbackText,
        reviewCloseButtonText,
        reviewSendButtonText,
        reviewButtonColor,
        activeStarColor,
        promotionalBackgroundColor,
        promotionalTextColor,
        promotionalTextSize,
        promotionalBorderColor,
        allergensTitleFontSize,
        allergensTitleColor,
        allergensTextFontSize,
        allergensTextColor,
      },
      backgroundImageChanged
    );
    setBackgroundImageChanged(false);
  };

  const contextValue = useMemo(
    () => ({
      personalizeActivated,
      setPersonalizeActivated,
      backgroundColor,
      setBackgroundColor,
      backgroundImage,
      setBackgroundImage,
      backgroundImageOpacity,
      setBackgroundImageOpacity,
      buttonsColor,
      setButtonsColor,
      linesColor,
      setLinesColor,
      iconsColor,
      setIconsColor,
      menuButtonSize,
      setMenuButtonSize,
      menuButtonAlign,
      setMenuButtonAlign,
      menuButtonContentAlign,
      setMenuButtonContentAlign,
      ourBackgroundImage,
      setOurBackgroundImage,
      textsColor,
      setTextsColor,
      secondaryTextsColor,
      setSecondaryTextsColor,
      titlesFontSize,
      setTitlesFontSize,
      textsFontSize,
      setTextsFontSize,
      categoriesFontSize,
      setCategoriesFontSize,
      importantTextsFontSize,
      setImportantTextsFontSize,
      informationText,
      setInformationText,
      informationSubtitleText,
      setInformationSubtitleText,
      informationsLinesColor,
      setInformationsLinesColor,
      informationCopiedTextColor,
      setInformationCopiedTextColor,
      reviewButtonTextColor,
      setReviewButtonTextColor,
      reviewButtonText,
      setReviewButtonText,
      reviewPageTitleText,
      setReviewPageTitleText,
      reviewFeedbackText,
      setReviewFeedbackText,
      reviewCloseButtonText,
      setReviewCloseButtonText,
      reviewSendButtonText,
      setReviewSendButtonText,
      reviewButtonColor,
      setReviewButtonColor,
      activeStarColor,
      setActiveStarColor,
      promotionalBackgroundColor,
      setPromotionalBackgroundColor,
      promotionalTextColor,
      setPromotionalTextColor,
      promotionalTextSize,
      setPromotionalTextSize,
      promotionalBorderColor,
      setPromotionalBorderColor,
      allergensTitleFontSize,
      setAllergensTitleFontSize,
      allergensTitleColor,
      setAllergensTitleColor,
      allergensTextFontSize,
      setAllergensTextFontSize,
      allergensTextColor,
      setAllergensTextColor,
      handleSaveStyle,
      handleChangePersonalizeActivation,
      handleShowContent,
      showContent,
      stylesFetched,
    }),
    [
      personalizeActivated,
      setPersonalizeActivated,
      backgroundColor,
      setBackgroundColor,
      backgroundImage,
      setBackgroundImage,
      backgroundImageOpacity,
      setBackgroundImageOpacity,
      buttonsColor,
      setButtonsColor,
      linesColor,
      setLinesColor,
      iconsColor,
      setIconsColor,
      menuButtonSize,
      setMenuButtonSize,
      menuButtonAlign,
      setMenuButtonAlign,
      menuButtonContentAlign,
      setMenuButtonContentAlign,
      ourBackgroundImage,
      setOurBackgroundImage,
      textsColor,
      setTextsColor,
      secondaryTextsColor,
      setSecondaryTextsColor,
      titlesFontSize,
      setTitlesFontSize,
      textsFontSize,
      setTextsFontSize,
      categoriesFontSize,
      setCategoriesFontSize,
      importantTextsFontSize,
      setImportantTextsFontSize,
      informationText,
      setInformationText,
      informationSubtitleText,
      setInformationSubtitleText,
      informationsLinesColor,
      setInformationsLinesColor,
      informationCopiedTextColor,
      setInformationCopiedTextColor,
      reviewButtonTextColor,
      setReviewButtonTextColor,
      reviewButtonText,
      setReviewButtonText,
      reviewPageTitleText,
      setReviewPageTitleText,
      reviewFeedbackText,
      setReviewFeedbackText,
      reviewCloseButtonText,
      setReviewCloseButtonText,
      reviewSendButtonText,
      setReviewSendButtonText,
      reviewButtonColor,
      setReviewButtonColor,
      activeStarColor,
      setActiveStarColor,
      promotionalBackgroundColor,
      setPromotionalBackgroundColor,
      promotionalTextColor,
      setPromotionalTextColor,
      promotionalTextSize,
      setPromotionalTextSize,
      promotionalBorderColor,
      setPromotionalBorderColor,
      allergensTitleFontSize,
      setAllergensTitleFontSize,
      allergensTitleColor,
      setAllergensTitleColor,
      allergensTextFontSize,
      setAllergensTextFontSize,
      allergensTextColor,
      setAllergensTextColor,
      handleSaveStyle,
      handleChangePersonalizeActivation,
      handleShowContent,
      showContent,
      stylesFetched,
    ]
  );

  return (
    <PersonalizeContext.Provider value={contextValue}>
      {children}
    </PersonalizeContext.Provider>
  );
};
