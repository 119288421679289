import React from "react";
import CustomListButton from "../common/CustomListButton";
import LoginPageTemplate from "./LoginPageTemplate";
import LoginInputTextField from "./LoginInputTextField";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";
import { validateMail, validateName } from "../../services/loginService";
import { useTranslation } from "react-i18next";
import { useTitle } from "../../App";

function Register() {
  const navigate = useNavigate();
  const { t } = useTranslation("global");
  useTitle(`DoliQR - ${t("registerPageTitle")}`);

  const { handleSignUp } = React.useContext(AuthContext);

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");

  const inputsChecker = () => {
    if (
      email === "" ||
      password === "" ||
      firstName === "" ||
      lastName === ""
    ) {
      setError(t("errorPleaseFillEmailPasswordFirstnameAndName"));
      return false;
    }
    const emailValid = validateMail(email);
    const passwordValid = password.length >= 6;
    const firstNameValid = validateName(firstName);
    const lastNameValid = validateName(lastName);
    if (!emailValid) {
      setError(t("errorAddValidEmailAdress"));
      return false;
    } else if (!passwordValid) {
      setError(t("errorAddValidPassword"));
      return false;
    } else if (!firstNameValid) {
      setError(t("errorAddValidFirstname"));
      return false;
    } else if (!lastNameValid) {
      setError(t("errorAddValidName"));
      return false;
    }

    return emailValid && passwordValid && firstNameValid && lastNameValid;
  };

  const handleSubmit = async () => {
    if (!inputsChecker()) {
      return;
    }
    try {
      await handleSignUp(
        email,
        password.toString(),
        firstName,
        lastName,
        setError
      );
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSignInClick = () => {
    navigate(`/sign-in`);
  };

  return (
    <LoginPageTemplate
      title={t("registerPageTitle")}
      error={error}
      primaryButton={{
        text: t("loginPageRegisterText"),
        onClick: () => handleSubmit(),
      }}
      textFields={
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "0px 16px",
              gap: 10,
            }}
          >
            <LoginInputTextField
              title={t("textLastName")}
              padding={"0px"}
              autoComplete={"family-name"}
              setter={setLastName}
              name={"lastName"}
            />
            <LoginInputTextField
              autoComplete={"given-name"}
              title={t("textFirstname")}
              padding={"0px"}
              setter={setFirstName}
              name={"firstName"}
            />
          </div>
          <LoginInputTextField
            title={t("textEmail")}
            setter={setEmail}
            name={"email"}
          />
          <LoginInputTextField
            title={t("textPassword")}
            placeholder={t("textPasswordShortPlaceholder")}
            type={"password"}
            setter={setPassword}
          />
        </>
      }
      headerButton={
        <>
          <div
            style={{
              fontSize: 15,
              color: "var(--primary-text-color)",
            }}
          >
            {t("registerPageAlreadyHaveAccountQuestion")}
          </div>
          <CustomListButton
            styles={{
              width: "fit-content",
              fontSize: 16,
              paddingLeft: "16px",
              paddingRight: "16px",
              color: "var(--primary-text-color)",
              fontWeight: "500 !important",
              maxHeight: 36,
            }}
            onClick={handleSignInClick}
            text={t("loginPageAuthText")}
            primary={false}
          />
        </>
      }
    />
  );
}

export default Register;
