import React from "react";
import AddItemInputContainer from "../../common/AddItemInputContainer";
import ColorPickerPop from "../../common/ColorPickerPop";
import { useTranslation } from "react-i18next";
import { PersonalizeContext } from "../../Context/PersonalizeContext";
import DoliNotMainSidebarDetailContainer from "../../common/DoliNotMainSidebarDetailContainer";

function PersonalizeInformationModule() {
  const { t } = useTranslation("global");
  const {
    informationText,
    setInformationText,
    informationsLinesColor,
    setInformationsLinesColor,
    informationCopiedTextColor,
    setInformationCopiedTextColor,
    informationSubtitleText,
    setInformationSubtitleText,
  } = React.useContext(PersonalizeContext);
  return (
    <DoliNotMainSidebarDetailContainer
      title={t("sidebarPersonnalizeInformationsModuletDetailsTitle")}
    >
      <AddItemInputContainer
        titleFontSize={15}
        name={t("personalizeInformationText")}
        setter={setInformationText}
        value={informationText}
      />
      <AddItemInputContainer
        titleFontSize={15}
        name={t("personalizeInformationTextSubtitle")}
        setter={setInformationSubtitleText}
        value={informationSubtitleText}
      />
      <AddItemInputContainer
        titleFontSize={15}
        name={t("personalizeInformationsLinesColors")}
        notTextField={true}
      >
        <ColorPickerPop
          setColor={setInformationsLinesColor}
          color={informationsLinesColor}
        />
      </AddItemInputContainer>
      <AddItemInputContainer
        titleFontSize={15}
        name={t("personalizeInformationsCopiedTextColor")}
        notTextField={true}
      >
        <ColorPickerPop
          setColor={setInformationCopiedTextColor}
          color={informationCopiedTextColor}
        />
      </AddItemInputContainer>
    </DoliNotMainSidebarDetailContainer>
  );
}

export default PersonalizeInformationModule;
