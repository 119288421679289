import React, { createContext, useState, useMemo } from "react";
import { SettingsContext } from "./SettingsContext";
import { useTranslation } from "react-i18next";
import { bannerImagesList } from "../../bannerImagesList";

export const InformationsContext = createContext();

export const InformationsProvider = ({ children }) => {
  const { settings, fetchSettings } = React.useContext(SettingsContext);
  const { t } = useTranslation("global");

  const [restaurantName, setRestaurantName] = React.useState(
    settings?.basic?.establishmentName
  );
  const [restaurantPublicMenuUrl, setRestaurantPublicMenuUrl] = React.useState(
    settings?.basic?.publicMenuURL
  );
  const [restaurantAddress, setRestaurantAddress] = React.useState(
    settings?.basic?.address
  );
  const [restaurantPhoneNumber, setRestaurantPhoneNumber] = React.useState(
    settings?.basic?.phoneNumber
  );
  const [message, setMessage] = React.useState(settings?.basic?.message);
  const [showRestaurantName, setShowRestaurantName] = React.useState(
    settings?.appearance?.showRestaurantName
  );
  const [theme, setTheme] = React.useState(settings?.appearance?.themeColor);
  const [image, setImage] = React.useState(null);
  const [customImage, setCustomImage] = React.useState(
    settings?.appearance?.banner?.custom
      ? settings?.appearance?.banner?.value
      : null
  );
  const [display, setDisplay] = React.useState(settings?.review?.display);
  const [link, setLink] = React.useState(settings?.review?.link);
  const [networkName, setNetworkName] = React.useState(
    settings?.wifi?.networkName
  );
  const [password, setPassword] = React.useState(settings?.wifi?.password);
  const [instagram, setInstagram] = React.useState(
    settings?.socialNetworks?.instagram
  );
  const [facebook, setFacebook] = React.useState(
    settings?.socialNetworks?.facebook
  );
  const [twitter, setTwitter] = React.useState(settings?.socialNetworks?.x);

  const handleFetchSettings = async () => {
    try {
      const settingsFetched = await fetchSettings();
      setRestaurantName(settingsFetched?.basic?.establishmentName);
      setRestaurantPublicMenuUrl(settingsFetched?.basic?.publicMenuURL);
      setRestaurantAddress(settingsFetched?.basic?.address);
      setRestaurantPhoneNumber(settingsFetched?.basic?.phoneNumber);
      setMessage(settingsFetched?.basic?.message);
      setShowRestaurantName(settingsFetched?.appearance?.showRestaurantName);
      setTheme(settingsFetched?.appearance?.themeColor);
      setDisplay(settingsFetched?.review?.display);
      setLink(settingsFetched?.review?.link);
      setNetworkName(settingsFetched?.wifi?.networkName);
      setPassword(settingsFetched?.wifi?.password);
      setInstagram(settingsFetched?.socialNetworks?.instagram);
      setFacebook(settingsFetched?.socialNetworks?.facebook);
      setTwitter(settingsFetched?.socialNetworks?.x);

      console.log("settingsFetched?", settingsFetched);
      console.log("settingsFetched.basic?", settingsFetched.basic);
      console.log(
        "settingsFetched?.basic?.establishmentName",
        settingsFetched.basic?.establishmentName
      );

      if (image === null) {
        if (
          settingsFetched?.appearance?.banner?.affiliateUrl !== "none" &&
          settingsFetched?.appearance?.banner?.affiliateUrl !== "" &&
          settingsFetched?.appearance?.banner?.affiliateUrl
        ) {
          setImage(settingsFetched?.appearance?.banner?.affiliateUrl);
        } else if (
          settingsFetched?.appearance?.banner?.custom &&
          settingsFetched?.appearance?.banner?.value
        ) {
          setImage(settingsFetched?.appearance?.banner?.value);
        } else if (
          !settingsFetched?.appearance?.banner?.custom &&
          settingsFetched?.appearance?.banner?.value
        ) {
          const imageFound = bannerImagesList.find((img) => {
            return img.name === settingsFetched?.appearance?.banner?.value;
          });
          setImage(imageFound);
        } else {
          setImage(null);
        }
      }
    } catch (error) {
      console.error("Error fetching settings", error);
    }
  };
  React.useState(() => {
    handleFetchSettings();
  }, [settings]);

  const contextValue = useMemo(
    () => ({
      restaurantName,
      setRestaurantName,
      restaurantPublicMenuUrl,
      setRestaurantPublicMenuUrl,
      restaurantAddress,
      setRestaurantAddress,
      restaurantPhoneNumber,
      setRestaurantPhoneNumber,
      message,
      setMessage,
      showRestaurantName,
      setShowRestaurantName,
      theme,
      setTheme,
      image,
      setImage,
      customImage,
      setCustomImage,
      display,
      setDisplay,
      link,
      setLink,
      networkName,
      setNetworkName,
      password,
      setPassword,
      instagram,
      setInstagram,
      facebook,
      setFacebook,
      twitter,
      setTwitter,
    }),
    [
      restaurantName,
      setRestaurantName,
      restaurantPublicMenuUrl,
      setRestaurantPublicMenuUrl,
      restaurantAddress,
      setRestaurantAddress,
      restaurantPhoneNumber,
      setRestaurantPhoneNumber,
      message,
      setMessage,
      showRestaurantName,
      setShowRestaurantName,
      theme,
      setTheme,
      image,
      setImage,
      customImage,
      setCustomImage,
      display,
      setDisplay,
      link,
      setLink,
      networkName,
      setNetworkName,
      password,
      setPassword,
      instagram,
      setInstagram,
      facebook,
      setFacebook,
      twitter,
      setTwitter,
    ]
  );

  return (
    <InformationsContext.Provider value={contextValue}>
      {children}
    </InformationsContext.Provider>
  );
};
