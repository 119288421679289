import React from "react";
import Container from "@mui/material/Container";
import ProductCard from "../common/ProductCard";
import ProductListFooter from "./ProductListFooter";
import TooltipMenu from "../common/TooltipMenu";
import EditProductModal from "../EditProduct/EditProductModal";
import EditCategorieModal from "../EditCategorie/EditCategorieModal";
import { CategoriesContext } from "../Context/CategoriesContext";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import EditMenuModal from "../EditMenu/EditMenuModal";
import { iconsList } from "../../iconsList";
import { imagesList } from "../../imagesList";
import { useTitle } from "../../App";
import SubscriptionLimit from "../SubscriptionLimit/SubscriptionLimit";
import { ALL_ITEMS } from "../../consts";
import { IOSSwitch } from "../common/IOSSwitch";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Divider from "../common/Divider";
import { useTranslation } from "react-i18next";
import {
  DoliNavigationContext,
  drawerBasicSecondaryListActions,
} from "../Context/DoliNavigationContext";
import CreateProductModal from "../CreateProduct/CreateProductModal";
import CreateCategorieModal from "../CreateCategorie/CreateCategorieModal";

function ProductsList() {
  const { menuId } = useParams();
  const { t } = useTranslation("global");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    categories,
    menuInformation,
    deleteCategory,
    moveCategory,
    movePlat,
    deletePlat,
    fetchCategories,
    updateHideCategories,
    hideCategories,
  } = React.useContext(CategoriesContext);
  useTitle(
    menuInformation?.name
      ? `DoliQR - ${t("menuPageTitle")} ${menuInformation?.name}`
      : `DoliQR - ${t("menuPageTitle")}`
  );
  const { drawerAction, reinitializeDrawerAction } = React.useContext(
    DoliNavigationContext
  );
  const [openEditProduct, setOpenEditProduct] = React.useState(false);
  const [productToEdit, setProductToEdit] = React.useState(null);
  const [openEditCategorie, setOpenEditCategorie] = React.useState(false);
  const [categorieToEdit, setCategorieToEdit] = React.useState(null);
  const [openEditMenu, setOpenEditMenu] = React.useState(false);
  const upgrade = searchParams.get("upgrade");
  const [showSubscriptionLimitModal, setShowSubscriptionLimitModal] =
    React.useState(upgrade);
  const [hideCategoriesState, setHideCategoriesState] = React.useState(
    hideCategories ?? false
  );
  const [openDish, setOpenDish] = React.useState(false);
  const [categoryId, setCategoryId] = React.useState(null);

  const handleOpenDish = (categoryId) => {
    setCategoryId(categoryId);
    setOpenDish(true);
  };
  const handleCloseDish = () => {
    setOpenDish(false);
    setCategoryId(null);
  };

  const [openCategorie, setOpenCategorie] = React.useState(false);
  const handleOpenCategorie = () => {
    setOpenCategorie(true);
  };
  const handleCloseCategorie = () => setOpenCategorie(false);

  const handleDrawerAction = () => {
    switch (drawerAction) {
      case drawerBasicSecondaryListActions.ADD_NEW_DISH:
        handleOpenDish();
        reinitializeDrawerAction();
        break;
      case drawerBasicSecondaryListActions.ADD_NEW_CATEGORY:
        handleOpenCategorie();
        reinitializeDrawerAction();
        break;
    }
  };

  React.useEffect(() => {
    console.log("[MenusList]drawerAction", drawerAction);
    if (drawerAction) {
      // Vérifier si drawerAction n'est pas null
      handleDrawerAction();
    }
  }, [drawerAction, handleDrawerAction]);

  React.useEffect(() => {
    console.log("[ProductsList]hideCategories", hideCategories);
    setHideCategoriesState(hideCategories);
  }, [hideCategories]);

  const handleHideCategories = (event) => {
    const newState = updateHideCategories(menuId, event.target.checked);
    setHideCategoriesState(newState);
  };

  {
    /**
     * TODO(chris):
     * Map each product image to not do redondant code
     */
  }

  const handleOpenEditMenu = (product) => {
    setOpenEditMenu(true);
  };

  const handleCloseEditMenu = () => {
    setOpenEditMenu(false);
  };

  const handleOpenEditProduct = (product) => {
    setProductToEdit(product);
    setOpenEditProduct(true);
  };

  const handleCloseEditProduct = () => {
    setProductToEdit(null);
    setOpenEditProduct(false);
  };

  const handleOpenEditCategorie = (product) => {
    setCategorieToEdit(product);
    setOpenEditCategorie(true);
  };

  const handleCloseEditCategorie = () => {
    setCategorieToEdit(null);
    setOpenEditCategorie(false);
  };

  const categorySettings = (categoryId, index) => {
    const isFirst = index === 0;
    const isLast = index === categories.length - 1;

    return [
      {
        name: t("textEdit"),
        action: () => handleOpenEditCategorie(categories[index]),
      },
      { name: t("textDelete"), action: () => deleteCategory(categoryId) },
      {
        name: t("textMoveUp"),
        action: () => moveCategory(categoryId, "moveUp"),
        hidden: isFirst,
      },
      {
        name: t("textMoveDown"),
        action: () => moveCategory(categoryId, "moveDown"),
        hidden: isLast,
      },
    ];
  };

  const platSettings = (articleId, index, plats) => {
    const isFirst = index === 0;
    const isLast = index === plats.length - 1;

    return [
      {
        name: t("textEdit"),
        action: () => handleOpenEditProduct(plats[index]),
      },
      { name: t("textDelete"), action: () => deletePlat(articleId) },
      {
        name: t("textMoveUp"),
        action: () => movePlat(articleId, "moveUp"),
        hidden: isFirst,
      },
      {
        name: t("textMoveDown"),
        action: () => movePlat(articleId, "moveDown"),
        hidden: isLast,
      },
    ];
  };

  React.useEffect(() => {
    if (menuId) {
      fetchCategories(menuId);
    }
  }, [menuId, openEditMenu]);

  return (
    <>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
            cursor: "pointer",
          }}
          onClick={() => handleOpenEditMenu()}
        >
          {menuInformation?.icon && (
            <div
              style={{
                fontSize: 18,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                borderRadius: 6,
                height: 42,
                width: 42,
                minWidth: 32,
                backgroundColor: "var(--white-svg-background)",
              }}
            >
              {
                iconsList.find(
                  (icon) => icon.name === menuInformation?.icon ?? "fork"
                ).icon
              }
            </div>
          )}
          <h1>{menuInformation?.name ?? ""}</h1>
        </div>

        <Divider
          propsStyle={{
            margin: "10px 0px",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
          }}
        >
          <div
            style={{
              fontSize: 18,
              fontWeight: "600",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 10,
            }}
          >
            <VisibilityOffIcon />
            {t("menuPageHideCategories")}
          </div>
          <div
            style={{
              width: "auto",
              marginRight: "auto",
              "--switch-height-container-size": "30px",
              "--switch-width-container-size": "100px",
            }}
          >
            <IOSSwitch
              height={30}
              checked={hideCategoriesState}
              onChange={handleHideCategories}
            />
          </div>
        </div>

        <Divider
          propsStyle={{
            margin: "10px 0px 20px",
          }}
        />

        {categories &&
          categories
            .sort((a, b) => a.order - b.order)
            .map(
              (category, categoryIndex) =>
                category.name !== ALL_ITEMS && (
                  <div key={category._id} style={{ marginBottom: 30 }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: 15,
                        flex: 2,
                      }}
                    >
                      <div
                        onClick={() =>
                          handleOpenEditCategorie(categories[categoryIndex])
                        }
                        style={{
                          cursor: "pointer",
                          flex: 1,
                          overflow: "hidden",
                        }}
                      >
                        <h2 style={{ marginBottom: 0, marginTop: 0 }}>
                          {category.name}
                        </h2>
                        <div
                          style={{
                            marginTop: 8,
                            color: "var(--category-secondary-text-color)",
                            overflow: "hidden",
                          }}
                        >
                          {category.description}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          height: "100%",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          flex: 1,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor:
                              "var(--product-card-background-color)",
                            borderRadius: 100,
                            height: 30,
                            width: 30,
                          }}
                        >
                          <TooltipMenu
                            itemsMenu={categorySettings(
                              category._id,
                              categoryIndex
                            )}
                            height={20}
                          />
                        </div>
                      </div>
                    </div>
                    {category.plats
                      .sort((a, b) => a.order - b.order)
                      .map((product, platIndex) => (
                        <ProductCard
                          key={product._id}
                          name={product.name}
                          description={product.description}
                          price={product.price}
                          image={
                            !product.image.custom
                              ? product.image.value !== undefined &&
                                product.image.value !== "none"
                                ? imagesList.find(
                                    (img) => img.name === product.image.value
                                  ).image
                                : null
                              : null
                          }
                          customImage={
                            product.image.custom
                              ? product.image.value !== undefined
                                ? product.image.value
                                : null
                              : null
                          }
                          first={platIndex === 0}
                          settings={platSettings(
                            product._id,
                            platIndex,
                            category.plats
                          )}
                          onClick={() =>
                            handleOpenEditProduct(category.plats[platIndex])
                          }
                        />
                      ))}
                    <ProductListFooter
                      categoryId={category._id}
                      alone={category.plats.length === 0}
                      handleOpenDish={handleOpenDish}
                      handleOpenCategorie={handleOpenCategorie}
                    />
                  </div>
                )
            )}
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 15,
              flex: 2,
            }}
          >
            <div
              style={{
                cursor: "pointer",
                flex: 1,
                overflow: "hidden",
              }}
            >
              <h2 style={{ marginBottom: 0, marginTop: 0 }}>{t("textAll")}</h2>
            </div>
          </div>

          <div style={{ marginBottom: 30 }}>
            {categories &&
              categories
                .sort((a, b) => a.order - b.order)
                .map((category, categoryIndex) => (
                  <>
                    {category.plats
                      .sort((a, b) => a.order - b.order)
                      .map((product, platIndex) => (
                        <ProductCard
                          key={product._id}
                          name={product.name}
                          description={product.description}
                          price={product.price}
                          image={
                            !product.image.custom
                              ? product.image.value !== undefined &&
                                product.image.value !== "none"
                                ? imagesList.find(
                                    (img) => img.name === product.image.value
                                  ).image
                                : null
                              : null
                          }
                          customImage={
                            product.image.custom
                              ? product.image.value !== undefined
                                ? product.image.value
                                : null
                              : null
                          }
                          first={platIndex === 0 && categoryIndex === 0}
                          settings={platSettings(
                            product._id,
                            platIndex,
                            category.plats
                          )}
                          onClick={() =>
                            handleOpenEditProduct(category.plats[platIndex])
                          }
                        />
                      ))}
                  </>
                ))}
          </div>
        </>
        <div style={{ marginBottom: 30 }}>
          <ProductListFooter
            showCreateDish={true}
            alone={true}
            menuId={menuId}
            handleOpenDish={handleOpenDish}
            handleOpenCategorie={handleOpenCategorie}
          />
        </div>
      </Container>
      {openEditProduct && (
        <EditProductModal
          open={openEditProduct}
          handleClose={handleCloseEditProduct}
          categoryId={productToEdit.categorie}
          name={productToEdit.name}
          description={productToEdit.description}
          price={productToEdit.price}
          allergensList={productToEdit.allergens}
          image={
            !productToEdit.image.custom
              ? productToEdit.image.value !== undefined &&
                productToEdit.image.value !== "none"
                ? productToEdit.image.value
                : null
              : null
          }
          customImage={
            productToEdit.image.custom
              ? productToEdit.image.value !== undefined
                ? productToEdit.image.value
                : null
              : null
          }
          id={productToEdit._id}
        />
      )}
      {showSubscriptionLimitModal && (
        <SubscriptionLimit
          open={showSubscriptionLimitModal}
          closeModal={() => {
            setShowSubscriptionLimitModal(false);
            navigate(`/user/menus/${menuId}`);
          }}
        />
      )}
      {openEditCategorie && (
        <EditCategorieModal
          open={openEditCategorie}
          handleClose={handleCloseEditCategorie}
          name={categorieToEdit.name}
          description={categorieToEdit.description}
          id={categorieToEdit._id}
        />
      )}

      {openEditMenu && (
        <EditMenuModal
          open={openEditMenu}
          handleClose={handleCloseEditMenu}
          id={menuId}
          name={menuInformation?.name ?? ""}
          description={menuInformation?.description ?? ""}
          icon={menuInformation?.icon ?? "fork"}
        />
      )}

      {openDish && (
        <CreateProductModal
          categoryId={categoryId}
          open={openDish}
          handleClose={handleCloseDish}
          menuId={menuId}
        />
      )}
      {openCategorie && (
        <CreateCategorieModal
          open={openCategorie}
          handleClose={handleCloseCategorie}
          menuId={menuId}
        />
      )}
    </>
  );
}

export default ProductsList;
