import React from "react";

function DoliNotMainSidebarDetailContainer({ title, children }) {
  return (
    <div
      style={{
        width: "100%",
        maxWidth: "100%",
        overflowX: "hidden",
        paddingTop: 12,
        gap: 20,
        display: "flex",
        flexDirection: "column",
        paddingBottom: 20,
      }}
    >
      {title && (
        <div
          style={{
            fontSize: 18,
            fontWeight: 600,
            color: "var(--black-text-color)",
            marginBottom: 0,
          }}
        >
          {title}
        </div>
      )}
      {children}
    </div>
  );
}

export default DoliNotMainSidebarDetailContainer;
