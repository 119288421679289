import React from "react";
import Container from "@mui/material/Container";
import SettingsButton from "./SettingsButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ColorLensOutlinedIcon from "@mui/icons-material/ColorLensOutlined";
import WifiOutlinedIcon from "@mui/icons-material/WifiOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import BasicInformation from "./Content/BasicInformation";
import { useTranslation } from "react-i18next";
import Appearance from "./Content/Apparence";
import Wifi from "./Content/Wifi";
import SocialNetworks from "./Content/SocialNetworks";
import { SettingsContext } from "../Context/SettingsContext";
import SuccessModal from "../common/SuccessModal";
import Review from "./Content/Review";
import { useTitle } from "../../App";
import { FullPageSxStyle } from "../common/FullPageStyle";
import DoliTopPageTitle from "../common/DoliTopPageTitle";

function Settings() {
  const { t } = useTranslation("global");
  useTitle(`DoliQR - ${t("settingsTitle")}`);

  const { changesModal, setChangesModal, settings, fetchSettings } =
    React.useContext(SettingsContext);
  const [showContent, setShowContent] = React.useState("");

  const size = 32;
  const settingsList = [
    {
      title: t("settingsBasicInformationsTitle"),
      subTitle: t("settingsBasicInformationsSubTitle"),
      icon: <InfoOutlinedIcon sx={{ height: size }} />,
      children: <BasicInformation />,
    },
    {
      title: t("settingsApparenceTitle"),
      subTitle: t("settingsApparenceSubTitle"),
      icon: <ColorLensOutlinedIcon sx={{ height: size }} />,
      children: <Appearance />,
    },
    {
      title: t("settingsReviewTitle"),
      subTitle: t("settingsReviewSubTitle"),
      icon: <ChatOutlinedIcon sx={{ height: size }} />,
      children: <Review />,
    },
    {
      title: t("settingsWifiTitle"),
      subTitle: t("settingsWifiSubTitle"),
      icon: <WifiOutlinedIcon sx={{ height: size }} />,
      children: <Wifi />,
    },
    {
      title: t("settingsSocialNetworksTitle"),
      subTitle: t("settingsSocialNetworksSubTitle"),
      icon: <ShareOutlinedIcon sx={{ height: size }} />,
      children: <SocialNetworks />,
    },
  ];

  /*
  {
    title: t("settingsExtraInformationTitle"),
    subTitle: t("settingsExtraInformationSubTitle"),
    icon: <ChatOutlinedIcon sx={{ height: size }} />,
    children: <ExtraInformation />,
  }
*/

  const handleShowContent = (title) => {
    setShowContent(title);
  };

  React.useEffect(() => {
    if (
      !settings?.basic ||
      !settings?.appearance ||
      !settings?.wifi ||
      !settings?.socialNetworks
    ) {
      fetchSettings();
    }
    // eslint-disable-next-line
  }, [settings]);

  return (
    <>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: { xs: "0px !important", sm: "10px !important" },
          ...FullPageSxStyle,
        }}
        sx={{
          padding: { xs: "0px !important", md: "0px 24px !important" },
          "--border-radius-card": {
            xs: "0px",
            md: "8px",
          },
          ...FullPageSxStyle,
        }}
      >
        <DoliTopPageTitle title={t("settingsTitle")} addPadding={true} />
        <Container
          sx={{
            padding: "0px !important",
            gap: "10px",
            display: "flex",
            flexDirection: "column",
            ...FullPageSxStyle,
          }}
        >
          {settingsList.map((setting, index) => (
            <SettingsButton
              key={index}
              title={setting.title}
              subTitle={setting.subTitle}
              icon={setting.icon}
              children={setting.children}
              handleShowContent={handleShowContent}
              showContent={showContent}
            />
          ))}
        </Container>
      </Container>
      {changesModal && (
        <SuccessModal
          open={changesModal}
          handleClose={() => {
            setChangesModal(false);
            setShowContent("");
          }}
        />
      )}
    </>
  );
}

export default Settings;
