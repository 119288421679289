import React from "react";
import Container from "@mui/material/Container";
import ProductListItemContainer from "../common/ProductListItemContainer";
import FormatPaintIcon from "@mui/icons-material/FormatPaint";
import StarIcon from "../assets/star.svg";
import { useTranslation } from "react-i18next";
import { useTitle } from "../../App";
import FormatSizeOutlinedIcon from "@mui/icons-material/FormatSizeOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PersonalizePage from "./Content/PersonalizePage";
import PersonalizeText from "./Content/PersonalizeText";
import { IOSSwitch } from "../common/IOSSwitch";
import AddItemInputContainer from "../common/AddItemInputContainer";
import PersonalizeInformationModule from "./Content/PersonalizeInformationsModule";
import PersonalizeReviewModule from "./Content/PersonalizeReviewModule";
import CustomListButton from "../common/CustomListButton";
import { SettingsContext } from "../Context/SettingsContext";
import SuccessModal from "../common/SuccessModal";
import PersonalizePromotional from "./Content/PersonalizePromotionalText";
import PersonalizeAllergensText from "./Content/PersonalizeAllergensText";
import PanToolOutlinedIcon from "@mui/icons-material/PanToolOutlined";
import { PersonalizeContext } from "../Context/PersonalizeContext";
import { FullPageSxStyle } from "../common/FullPageStyle";

export const handlePersonalizeSliderChange = (event, newValue, setter) => {
  setter(newValue);
};

function Personalize() {
  const { t } = useTranslation("global");
  useTitle(`DoliQR - ${t("personalizePageTitle")}`);
  const { changesStyleModal, setChangesStyleModal } =
    React.useContext(SettingsContext);
  const {
    personalizeActivated,
    setPersonalizeActivated,
    backgroundColor,
    setBackgroundColor,
    backgroundImage,
    setBackgroundImage,
    backgroundImageOpacity,
    setBackgroundImageOpacity,
    buttonsColor,
    setButtonsColor,
    linesColor,
    setLinesColor,
    iconsColor,
    setIconsColor,
    menuButtonSize,
    setMenuButtonSize,
    menuButtonAlign,
    setMenuButtonAlign,
    menuButtonContentAlign,
    setMenuButtonContentAlign,
    ourBackgroundImage,
    setOurBackgroundImage,
    textsColor,
    setTextsColor,
    secondaryTextsColor,
    setSecondaryTextsColor,
    titlesFontSize,
    setTitlesFontSize,
    textsFontSize,
    setTextsFontSize,
    categoriesFontSize,
    setCategoriesFontSize,
    importantTextsFontSize,
    setImportantTextsFontSize,
    informationText,
    setInformationText,
    informationSubtitleText,
    setInformationSubtitleText,
    infromationsLinesColor,
    setInformationsLinesColor,
    infromationCopiedTextColor,
    setInformationCopiedTextColor,
    reviewButtonTextColor,
    setReviewButtonTextColor,
    reviewButtonText,
    setReviewButtonText,
    reviewPageTitleText,
    setReviewPageTitleText,
    reviewFeedbackText,
    setReviewFeedbackText,
    reviewCloseButtonText,
    setReviewCloseButtonText,
    reviewSendButtonText,
    setReviewSendButtonText,
    reviewButtonColor,
    setReviewButtonColor,
    activeStarColor,
    setActiveStarColor,
    promotionalBackgroundColor,
    setPromotionalBackgroundColor,
    promotionalTextColor,
    setPromotionalTextColor,
    promotionalTextSize,
    setPromotionalTextSize,
    promotionalBorderColor,
    setPromotionalBorderColor,
    allergensTitleFontSize,
    setAllergensTitleFontSize,
    allergensTitleColor,
    setAllergensTitleColor,
    allergensTextFontSize,
    setAllergensTextFontSize,
    allergensTextColor,
    setAllergensTextColor,
    handleSaveStyle,
    handleChangePersonalizeActivation,
    stylesFetched,
    showContent,
    handleShowContent,
    setShowContent,
  } = React.useContext(PersonalizeContext);
  const handleSendMailClick = () => {
    document.getElementById("sendEmail").click();
  };

  const optionList = [
    {
      title: t("personalizePresentationColorsTitle"),
      icon: <FormatPaintIcon />,
      children: (
        <PersonalizePage
          backgroundColor={backgroundColor}
          setBackgroundColor={setBackgroundColor}
          buttonsColor={buttonsColor}
          setButtonsColor={setButtonsColor}
          linesColor={linesColor}
          setLinesColor={setLinesColor}
          iconsColor={iconsColor}
          setIconsColor={setIconsColor}
          menuButtonSize={menuButtonSize}
          setMenuButtonSize={setMenuButtonSize}
          menuButtonAlign={menuButtonAlign}
          setMenuButtonAlign={setMenuButtonAlign}
          menuButtonContentAlign={menuButtonContentAlign}
          setMenuButtonContentAlign={setMenuButtonContentAlign}
          backgroundImage={backgroundImage}
          setBackgroundImage={setBackgroundImage}
          setOurBackgroundImage={setOurBackgroundImage}
          ourBackgroundImage={ourBackgroundImage}
          backgroundImageOpacity={backgroundImageOpacity}
          setBackgroundImageOpacity={setBackgroundImageOpacity}
        />
      ),
    },
    {
      title: t("personalizeTextTitle"),
      icon: <FormatSizeOutlinedIcon />,
      children: (
        <PersonalizeText
          titlesFontSize={titlesFontSize}
          setTitlesFontSize={setTitlesFontSize}
          secondaryTextsColor={secondaryTextsColor}
          setSecondaryTextsColor={setSecondaryTextsColor}
          textsColor={textsColor}
          setTextsColor={setTextsColor}
          textsFontSize={textsFontSize}
          setTextsFontSize={setTextsFontSize}
          categoriesTextFontSize={categoriesFontSize}
          setCategoriesTextFontSize={setCategoriesFontSize}
          importantTextsFontSize={importantTextsFontSize}
          setImportantTextsFontSize={setImportantTextsFontSize}
        />
      ),
    },
    {
      title: t("personalizeInformationModule"),
      icon: <InfoOutlinedIcon />,
      children: (
        <PersonalizeInformationModule
          informationText={informationText}
          setInformationText={setInformationText}
          informationsLinesColor={infromationsLinesColor}
          setInformationsLinesColor={setInformationsLinesColor}
          informationCopiedTextColor={infromationCopiedTextColor}
          setInformationCopiedTextColor={setInformationCopiedTextColor}
          informationSubtitleText={informationSubtitleText}
          setInformationSubtitleText={setInformationSubtitleText}
        />
      ),
    },
    {
      title: t("personalizeReviewModule"),
      icon: <StarIcon width={40} height={40} />,
      children: (
        <PersonalizeReviewModule
          reviewButtonTextColor={reviewButtonTextColor}
          setReviewButtonTextColor={setReviewButtonTextColor}
          reviewButtonColor={reviewButtonColor}
          setReviewButtonColor={setReviewButtonColor}
          activeStarColor={activeStarColor}
          setActiveStarColor={setActiveStarColor}
          reviewButtonText={reviewButtonText}
          setReviewButtonText={setReviewButtonText}
          reviewPageTitleText={reviewPageTitleText}
          setReviewPageTitleText={setReviewPageTitleText}
          reviewFeedbackText={reviewFeedbackText}
          setReviewFeedbackText={setReviewFeedbackText}
          reviewCloseButtonText={reviewCloseButtonText}
          setReviewCloseButtonText={setReviewCloseButtonText}
          reviewSendButtonText={reviewSendButtonText}
          setReviewSendButtonText={setReviewSendButtonText}
        />
      ),
    },
    {
      title: t("personalizePromotionalText"),
      icon: <InfoOutlinedIcon />,
      children: (
        <PersonalizePromotional
          promotionalBackgroundColor={promotionalBackgroundColor}
          setPromotionalBackgroundColor={setPromotionalBackgroundColor}
          promotionalTextColor={promotionalTextColor}
          setPromotionalTextColor={setPromotionalTextColor}
          promotionalTextSize={promotionalTextSize}
          setPromotionalTextSize={setPromotionalTextSize}
          promotionalBorderColor={promotionalBorderColor}
          setPromotionalBorderColor={setPromotionalBorderColor}
        />
      ),
    },
    {
      title: t("personalizeAllergensModule"),
      icon: <PanToolOutlinedIcon />,
      children: (
        <PersonalizeAllergensText
          allergensTitleFontSize={allergensTitleFontSize}
          setAllergensTitleFontSize={setAllergensTitleFontSize}
          allergensTitleColor={allergensTitleColor}
          setAllergensTitleColor={setAllergensTitleColor}
          allergensTextFontSize={allergensTextFontSize}
          setAllergensTextFontSize={setAllergensTextFontSize}
          allergensTextColor={allergensTextColor}
          setAllergensTextColor={setAllergensTextColor}
        />
      ),
    },
  ];

  return (
    <>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "0px !important",
          gap: 10,
          marginBottom: 56,
        }}
        sx={{
          maxWidth: { md: "auto", lg: 1136 },
          padding: { xs: "0px !important", md: "0px 24px !important" },
          "--border-radius-card": {
            xs: "0px",
            md: "8px",
          },
          ...FullPageSxStyle,
        }}
      >
        <Container
          sx={{
            padding: { xs: "0px 16px !important", md: "0px !important" },
            "--border-radius-card": {
              xs: "0px",
              md: "8px",
            },
            ...FullPageSxStyle,
          }}
        >
          <ProductListItemContainer
            style={{
              marginBottom: 15,
              paddingBottom: 15,
              paddingLeft: 0,
              paddingRight: 0,
              backgroundColor: "none",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "1px solid var(--border-color)",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 10,
            }}
            sx={{
              ...FullPageSxStyle,
            }}
          >
            <Container
              sx={{
                margin: "0px !important",
                padding: "0px !important",
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                ...FullPageSxStyle,
              }}
            >
              <h2 style={{ margin: 0 }}>{t("personalizePageTitle")}</h2>
              <Container
                sx={{
                  display: { xs: "none", sm: "flex", md: "flex" },
                  width: "auto",
                  margin: "0px !important",
                  padding: "0px !important",
                }}
              >
                <CustomListButton
                  text={t("textSave")}
                  big={true}
                  type={"submit"}
                  alignButtonLeft={true}
                  onClick={() => handleSaveStyle()}
                />
              </Container>
            </Container>
            <Container
              sx={{
                display: { xs: "flex", sm: "none", md: "none" },
                flexDirection: "column",
                backgroundColor: "var(--product-card-background-color)",
                borderRadius: "6px",
                padding: "8px",
                gap: "10px",
                ...FullPageSxStyle,
              }}
            >
              <div>
                <div style={{ fontWeight: "500" }}>
                  {t("personalizeOnBigScreen")}
                </div>
                <div style={{ fontWeight: "500" }}>
                  {t("personalizeOnBigScreenTips")}
                </div>
              </div>
              <a
                id="sendEmail"
                style={{ display: "none" }}
                href={`mailto:?subject=${t("personalizeEmailSubject")}&body=${t(
                  "personalizeEmailBody"
                )}`}
              />
              <CustomListButton
                onClick={handleSendMailClick}
                text={t("personalizeOnBigScreenSend")}
              />
            </Container>
          </ProductListItemContainer>
          <AddItemInputContainer
            titleFontSize={15}
            sxStyle={{
              ...FullPageSxStyle,
            }}
            name={
              <>
                {t("personalizeActivePersonalization")}:{" "}
                <span
                  style={{
                    color: personalizeActivated
                      ? "var(--active-text-color)"
                      : "var(--desactivated-text-color)",
                  }}
                >
                  {personalizeActivated
                    ? t("personalizeActivated")
                    : t("personalizeDeactivated")}
                </span>
              </>
            }
            notTextField={true}
          >
            <div
              style={{
                width: "auto",
                marginRight: "auto",
                "--switch-height-container-size": "30px",
                "--switch-width-container-size": "100px",
              }}
            >
              <IOSSwitch
                height={30}
                checked={personalizeActivated}
                onChange={handleChangePersonalizeActivation}
              />
            </div>
          </AddItemInputContainer>
        </Container>

        <Container
          sx={{
            margin: "0px !important",
            marginTop: 20,
            justifyContent: "flex-start",
            display: { xs: "flex", sm: "none", md: "none" },
            ...FullPageSxStyle,
          }}
        >
          <CustomListButton
            text={t("textSave")}
            big={true}
            type={"submit"}
            alignButtonLeft={true}
            minWidth={"100%"}
            onClick={() => handleSaveStyle()}
            styles={{
              margin: "0px !important",
            }}
          />
        </Container>
      </Container>
      {changesStyleModal && (
        <SuccessModal
          open={changesStyleModal}
          handleClose={() => {
            setChangesStyleModal(false);
          }}
        />
      )}
    </>
  );
}

export default Personalize;
