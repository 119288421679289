import React from "react";
import AddItemInputContainer from "../../common/AddItemInputContainer";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { InformationsContext } from "../../Context/InformationsContext";
import DoliNotMainSidebarDetailContainer from "../../common/DoliNotMainSidebarDetailContainer";

function Review() {
  const { t } = useTranslation("global");
  const { display, setDisplay, link, setLink } =
    React.useContext(InformationsContext);

  const handleDisplayChange = (event) => {
    setDisplay(event.target.value);
  };

  return (
    <DoliNotMainSidebarDetailContainer
      title={t("sidebarSettingsReviewDetailsTitle")}
    >
      <AddItemInputContainer
        name={t("settingsReviewDisplay")}
        titleFontSize={15}
        subTitleFontSize={15}
        notTextField={true}
      >
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={display}
          onChange={handleDisplayChange}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "start",
            width: "100%",
          }}
        >
          <FormControlLabel
            value={true}
            control={<Radio size={"15px"} />}
            label={t("textShow")}
            sx={{ ".MuiTypography-root": { fontSize: 15 } }}
          />
          <FormControlLabel
            value={false}
            control={<Radio size={"15px"} />}
            label={t("textDontShow")}
            sx={{ ".MuiTypography-root": { fontSize: 15 } }}
          />
        </RadioGroup>
      </AddItemInputContainer>
      <AddItemInputContainer
        setter={setLink}
        value={link}
        name={t("settingsReviewLink")}
        description={t("settingsReviewLinkInformation")}
        titleFontSize={15}
      />

      {/* <div
        style={{
          marginTop: 20,
          display: "flex",
          justifyContent: "flex-start",
          padding: "12px 24px",
          backgroundColor: "var(--bottom-background-color)",
          borderEndEndRadius: 8,
          borderEndStartRadius: 8,
        }}
      >
        <CustomListButton
          text={t("textSave")}
          big={true}
          type={"submit"}
          alignButtonLeft={true}
          onClick={() => {
            updateSettings({
              review: {
                link,
                display,
              },
            });
          }}
        />
      </div>*/}
    </DoliNotMainSidebarDetailContainer>
  );
}

export default Review;
