import React from "react";
import AddItemInputContainer from "../../common/AddItemInputContainer";
import { InputAdornment } from "@mui/material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { useTranslation } from "react-i18next";
import { InformationsContext } from "../../Context/InformationsContext";
import DoliNotMainSidebarDetailContainer from "../../common/DoliNotMainSidebarDetailContainer";

function SocialNetworks() {
  const { t } = useTranslation("global");
  const {
    instagram,
    setInstagram,
    facebook,
    setFacebook,
    twitter,
    setTwitter,
  } = React.useContext(InformationsContext);

  return (
    <DoliNotMainSidebarDetailContainer
      title={t("sidebarSettingsSocialNetworksDetailsTitle")}
    >
      <AddItemInputContainer
        name={t("settingsInstagram")}
        setter={setInstagram}
        value={instagram}
        titleFontSize={15}
        startAdornment={
          <InputAdornment position="end">
            <AlternateEmailIcon
              fontSize={"10px"}
              style={{ marginLeft: -10, marginRight: 5 }}
            />
          </InputAdornment>
        }
      />
      <AddItemInputContainer
        name={t("settingsFacebook")}
        setter={setFacebook}
        value={facebook}
        titleFontSize={15}
        startAdornment={
          <InputAdornment position="end">
            <AlternateEmailIcon
              fontSize={"10px"}
              style={{ marginLeft: -10, marginRight: 5 }}
            />
          </InputAdornment>
        }
      />
      <AddItemInputContainer
        name={t("settingsTwitter")}
        setter={setTwitter}
        value={twitter}
        titleFontSize={15}
        startAdornment={
          <InputAdornment position="end">
            <AlternateEmailIcon
              fontSize={"10px"}
              style={{ marginLeft: -10, marginRight: 5 }}
            />
          </InputAdornment>
        }
      />

      {/**<div
        style={{
          marginTop: 20,
          display: "flex",
          justifyContent: "flex-start",
          padding: "12px 24px",
          backgroundColor: "var(--bottom-background-color)",
          borderEndEndRadius: 8,
          borderEndStartRadius: 8,
        }}
      >
        <CustomListButton
          text={t("textSave")}
          big={true}
          type={"submit"}
          alignButtonLeft={true}
          onClick={() => {
            updateSettings({
              socialNetworks: {
                instagram,
                facebook,
                x: twitter,
              },
            });
          }}
        />
      </div>*/}
    </DoliNotMainSidebarDetailContainer>
  );
}

export default SocialNetworks;
