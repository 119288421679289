import React from "react";
import { getUser } from "../../services/userService";
import { API_URL } from "../../consts";

export const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
  const [doliQrUser, setDoliQrUser] = React.useState([]);
  const [currentMerchantId, setCurrentMerchantId] = React.useState(
    localStorage.getItem("currentMerchantId")
  );
  const [currentMerchantName, setCurrentMerchantName] = React.useState(
    localStorage.getItem("currentMerchantName")
  );
  const [hasChosenRestaurant, setHasChosenRestaurant] = React.useState(
    JSON.parse(localStorage.getItem("hasChosenRestaurant")) || false
  );
  const [currentMerchantSubscribed, setCurrentMerchantSubscribed] =
    React.useState(null);
  const [currentMerchantSubscription, setCurrentMerchantSubscription] =
    React.useState(null);

  const setupSingleEstablishment = async (doliQrUser) => {
    if (
      doliQrUser &&
      doliQrUser.merchantsList &&
      doliQrUser.merchantsList.length === 1
    ) {
      setCurrentMerchantId(doliQrUser.merchantsList[0]._id);
      setCurrentMerchantName(doliQrUser.merchantsList[0].establishmentName);
      setHasChosenRestaurant(true);
      localStorage.setItem(
        "currentMerchantId",
        doliQrUser.merchantsList[0]._id
      );
      localStorage.setItem(
        "currentMerchantName",
        doliQrUser.merchantsList[0].establishmentName
      );
      localStorage.setItem("hasChosenRestaurant", true);
    }
  };

  const changeMerchant = async (id, firebaseUserUuid = false) => {
    let doliQrUserGiven = false;
    if (firebaseUserUuid) {
      await getUser(firebaseUserUuid).then(async (doliQrUser) => {
        doliQrUserGiven = doliQrUser;
      });
    }
    if (
      (doliQrUser &&
        doliQrUser.merchantsList &&
        doliQrUser.merchantsList.length > 0) ||
      doliQrUserGiven
    ) {
      const doliQrUserToUse = doliQrUserGiven || doliQrUser;
      const merchant = doliQrUserToUse.merchantsList.find(
        (merchant) => merchant._id === id
      );
      setCurrentMerchantId(merchant._id);
      setCurrentMerchantName(merchant.establishmentName);
      setHasChosenRestaurant(true);
      localStorage.setItem("currentMerchantId", merchant._id);
      localStorage.setItem("currentMerchantName", merchant.establishmentName);
      localStorage.setItem("hasChosenRestaurant", true);
    }
  };

  const removeAll = () => {
    localStorage.removeItem("currentMerchantId");
    localStorage.removeItem("currentMerchantName");
    localStorage.removeItem("hasChosenRestaurant");
    setCurrentMerchantId(null);
    setCurrentMerchantName(null);
    setHasChosenRestaurant(false);
  };

  React.useEffect(() => {
    if (doliQrUser?.merchantsList?.length <= 1) {
      setupSingleEstablishment(doliQrUser);
    }
  }, [doliQrUser]);

  const getActualSubscription = async () => {
    try {
      const response = await fetch(
        `${API_URL}api/marchants/check-subscription/${currentMerchantId}`
      );
      const data = await response.json();
      if (
        data?.active === undefined ||
        !data.active ||
        data.active === null ||
        data.active === false ||
        data.active === "false" ||
        data.active === "null" ||
        data.active === "undefined" ||
        data.active === ""
      ) {
        setCurrentMerchantSubscribed(false);
        setCurrentMerchantSubscription(data);
      } else {
        setCurrentMerchantSubscribed(data.active);
        setCurrentMerchantSubscription(data);
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      setCurrentMerchantSubscribed(false);
      setCurrentMerchantSubscription({});
    }
  };

  const setTempSubscribed = (isMonthly) => {
    setCurrentMerchantSubscribed(true);
    setCurrentMerchantSubscription({ active: true, interval: isMonthly ? "MONTHLY" : "ANNUAL", price: 0});
  };

  React.useEffect(() => {
    if (currentMerchantId !== null) {
      console.log("currentMerchantId", currentMerchantId);
      getActualSubscription();
    }
  }, [currentMerchantId]);

  return (
    <UserContext.Provider
      value={{
        doliQrUser,
        setDoliQrUser,
        changeMerchant,
        currentMerchantId,
        currentMerchantName,
        setupSingleEstablishment,
        hasChosenRestaurant,
        setHasChosenRestaurant,
        removeAll,
        currentMerchantSubscribed,
        currentMerchantSubscription,
        setTempSubscribed,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
