import React from "react";
import Container from "@mui/material/Container";
import DoliMobileNavigation from "./DoliMobileNavigation";
import DoliBasicNavigation from "./DoliBasicNavigation";
import { DoliNavigationContext } from "../Context/DoliNavigationContext";
import { FullPageSxStyle } from "../common/FullPageStyle";

function DoliNavigation() {
  const { navigationProps } = React.useContext(DoliNavigationContext);
  console.log("[DoliNavigation]navigationProps", navigationProps);
  return (
    <>
      {!navigationProps.nothing && (
        <>
          <Container
            sx={{
              display: { xs: "flex", sm: "none", md: "none" },
              margin: "0px !important",
              padding: "0px !important",
              ...FullPageSxStyle,
            }}
          >
            <DoliMobileNavigation
              notMainPage={!navigationProps.mainPage}
              actualPageId={navigationProps.actualPageId}
              hideDetailSidebar={navigationProps.hideDetailSidebar}
            />
          </Container>
          <Container
            sx={{
              display: { xs: "none", sm: "flex", md: "flex" },
              margin: "0px !important",
              padding: "0px !important",
              ...FullPageSxStyle,
            }}
          >
            <DoliBasicNavigation
              notMainPage={!navigationProps.mainPage}
              actualPageId={navigationProps.actualPageId}
              hideDetailSidebar={navigationProps.hideDetailSidebar}
            />
          </Container>
        </>
      )}
    </>
  );
}
export default DoliNavigation;