import React from "react";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import { Toolbar } from "@mui/material";
import DoliButton from "../common/DoliButton";
import { useNavigate } from "react-router-dom";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import DoliMobileDrawer from "./DoliMobileDrawer";
import HomeIcon from "@mui/icons-material/Home";
import { FullPageSxStyle } from "./FullPageStyle";

function DoliTopBarNavigationNotMain({ actualPageId = 0 }) {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = React.useState(false);

  const toggleDrawer = React.useCallback(() => {
    console.log("[DoliTopBarNavigationNotMain] toggleDrawer", openDrawer);
    setOpenDrawer(!openDrawer);
  }, [openDrawer]);

  React.useEffect(() => {
    console.log("[DoliTopBarNavigationNotMain] openDrawer", openDrawer);
  }, [openDrawer]);

  return (
    <>
      <div>
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            margin: "0px !important",
            padding: "0px !important",
            ...FullPageSxStyle,
          }}
        >
          <Container
            maxWidth="xl"
            sx={{
              backgroundColor: "",
              backgroundColor: "rgb(139,61,255)",
              background:
                "linear-gradient(90deg, rgba(139,61,255,1) 0%, rgba(79,0,255,1) 49%, rgba(0,212,255,1) 100%)",
              position: "fixed",
              zIndex: 2,
              top: 0,
              ...FullPageSxStyle,
              paddingLeft: "0px !important",
              right: 0,
            }}
          >
            <Toolbar
              disableGutters
              sx={{
                backgroundColor: "transparent !important",
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                paddingLeft: "0px !important",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Container
                  sx={{
                    height: "auto",
                    width: "72px !important",
                    margin: "0px !important",
                    padding: "8px !important",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "transparent",
                    borderRadius: "var(--button-border-radius)",
                    cursor: "pointer",
                    ".MuiSvgIcon-fontSizeMedium": {
                      fontSize: "var(--icon-size) !important",
                      color: "white",
                    },
                  }}
                  onClick={() => toggleDrawer()}
                >
                  <MenuIcon fontSize="medium" />
                </Container>

                <Container
                  sx={{
                    height: "auto",
                    width: "auto !important",
                    padding: "8px !important",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "transparent",
                    borderRadius: "var(--button-border-radius)",
                    cursor: "pointer",
                    ".MuiSvgIcon-fontSizeMedium": {
                      fontSize: "var(--icon-size) !important",
                      color: "white",
                    },
                  }}
                  onClick={() => navigate("/user/dashboard")}
                >
                  <HomeIcon />
                </Container>
              </div>

              <DoliButton
                onClick={() =>
                  navigate("/user/billing?backPage=/user/dashboard")
                }
                iconBefore={
                  <ArrowCircleUpIcon
                    style={{
                      fontSize: "var(--icon-size)",
                      color: "var(--white-color)",
                      marginRight: "8px",
                    }}
                  />
                }
                backgroundColorProps={"transparent"}
                borderColorProps={"white"}
                backgroundColorHoverProps={
                  "var(--white-button-hover-color-w-transparent-background)"
                }
                height={"38px"}
                text={"Changer de Forfait"}
              />
            </Toolbar>
          </Container>
        </Container>
      </div>
      <DoliMobileDrawer
        openDrawer={openDrawer}
        toggleDrawer={() => toggleDrawer()}
        actualPageId={actualPageId}
        drawerWidth={"400px"}
      />
    </>
  );
}
export default DoliTopBarNavigationNotMain;
