import * as React from "react";
import CustomListButton from "../CustomListButton";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import { Container } from "@mui/material";
import ChooseImageLibrary from "./ChooseImageLibrary";

export default function UploadFolderButton({
  image,
  setImage,
  customImage,
  setCustomImage,
  imageListToUse,
  showCategory,
}) {
  const [displayChooseImageModal, setDisplayChooseImageModal] =
    React.useState(false);

  return (
    <>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          padding: "0px !important",
          height: "auto",
        }}
      >
        <CustomListButton
          height="auto"
          onClick={() => setDisplayChooseImageModal(true)}
          iconBefore={
            image !== null || customImage !== null ? (
              <></>
            ) : (
              <ImageOutlinedIcon
                style={{
                  color: "var(--gray-icon-color)",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: 40,
                  height: 40,
                }}
              />
            )
          }
          styles={{
            width: "100%",
            maxHeight: 200,
            padding: "0px 16px",
            fontSize: 16,
            fontWeight: "500 !important",
            borderStyle: "dotted !important",
            flexDirection: "column",
            padding: image ? "0px !important" : "30px !important",
          }}
          text={
            image !== null || customImage !== null ? (
              <img
                src={image?.image || customImage?.image || customImage}
                style={{
                  width: "200px",
                  height: "200px",
                  maxHeight: 200,
                  objectFit: "cover",
                  borderRadius: 4,
                }}
              />
            ) : (
              <div
                style={{
                  fontSize: 14,
                  marginTop: 12,
                  color: "var(--colored-action-text-color)",
                }}
              >
                Parcourir ou mettre en ligne une image
              </div>
            )
          }
          primary={false}
        />
      </Container>
      {displayChooseImageModal && (
        <ChooseImageLibrary
          imageSelected={image}
          setImage={setImage}
          setCustomImage={setCustomImage}
          open={displayChooseImageModal}
          handleClose={() => setDisplayChooseImageModal(false)}
          imageListToUse={imageListToUse}
          showCategory={showCategory}
        />
      )}
    </>
  );
}
