import React from "react";
import Container from "@mui/material/Container";
import DoliNavigationBasicMainCard from "../common/DoliNavigationBasicMainCard";
import { DoliNavigationContext } from "../Context/DoliNavigationContext";

function DoliSidebarNavigationNotMain({
  actualPageId = 0,
  hideDetailSidebar = false,
  isMobile = false,
}) {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const {
    closeDrawer,
    handleOpenDrawer,
    drawerBasicNotMainSecondaryList,
    drawerSecondaryListSelected,
  } = React.useContext(DoliNavigationContext);

  const handleNavigationCardClick = (type) => {
    console.log("[DoliBasicNavigation] handleProfileButtonClick");
    if (type.onClick) {
      type.onClick();
    }
    handleOpenDrawer();
  };

  return (
    <>
      <Container
        sx={{
          width: {
            xs: "72px",
            sm: "72px",
            md: hideDetailSidebar === false ? "328px" : "72px",
          },
          margin: "0px !important",
          padding: "0px !important",
          backgroundColor: "var(--sidebar-not-main-background-color)",
          position: "fixed",
          zIndex: 4,
          left: 0,
          top: isMobile ? 56 : 64,
          display: "flex",
          justifyContent: "flex-start",
          border: "0px 1px 0px 0px solid var(--border-color)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: isMobile
            ? "calc(calc(100% - calc(var(--mobile-bottom-navigation-height) + var(--mobile-topbar-height))) - 1px)"
            : "100%",
        }}
      >
        <div>
          <Container
            sx={{
              width: "content",
              backgroundColor: "var(--sidebar-not-main-background-color)",
              height: "100%",
              position: "absolute",
              left: 0,
              opacity: "0.05",
              margin: "0px !important",
              padding: "0px !important",
              zIndex: -1,
            }}
          />
          <Container
            sx={{
              width: "72px",
              margin: "0px !important",
              padding: "0px !important",
              height: "100%",
              position: "fixed",
              zIndex: 1,
              left: 0,
              display: "flex",
              justifyContent: "flex-start",
              height: isMobile ? "0px" : "",
            }}
          >
            <Container
              sx={{
                margin: "16px 0px !important",
                padding: "0px !important",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "16px",
                height: isMobile ? "0px" : "",
              }}
            >
              {drawerBasicNotMainSecondaryList({
                id: actualPageId,
              })?.primaryList?.map((item) => (
                <DoliNavigationBasicMainCard
                  icon={item.icon}
                  text={item.title}
                  textStyles={{
                    fontSize: "14px",
                    color: "var(--black-color)",
                  }}
                  iconColorInActive={"var(--black-color)"}
                  iconSize={"14px"}
                  iconSizeInactive={"20px"}
                  active={item.id === drawerSecondaryListSelected}
                  onClick={() =>
                    handleNavigationCardClick({
                      onClick: item.onClick,
                    })
                  }
                />
              ))}
            </Container>
          </Container>
        </div>
        {hideDetailSidebar === false && !closeDrawer && (
          <Container
            sx={{
              width: "calc(var(--sidebar-not-main-width) + 1px)",
              margin: "0px !important",
              padding: "0px !important",
              borderRadius: "8px",
              backgroundColor: "var(--sidebar-not-main-background-color)",
              height: isMobile ? "calc(100%)" : "calc(100% - 64px)",
              position: "absolute",
              zIndex: 3,
              left: "calc(var(--sidebar-toolbar-not-main-width) + 6px)",
            }}
          >
            <Container
              sx={{
                width: "calc(var(--sidebar-not-main-width) + 1px)",
                backgroundColor: "white",
                borderRadius: "8px",
                margin: "0px 0px 0px 0px !important",
                padding: "0px 0px 0px 0px !important",
                overflow: "hidden",
                position: "fixed",
                height: isMobile
                  ? "calc(calc(100% - calc(var(--mobile-bottom-navigation-height) + 56px)) - 1px)"
                  : "calc(100% - 64px)",
                zIndex: 5,
                left: "calc(var(--sidebar-toolbar-not-main-width) + 6px)",
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
              }}
            >
              <Container
                id="mobileNotMainContainer"
                sx={{
                  borderRadius: "8px",
                  boxShadow:
                    "0px 0px 0px 1px rgba(64,87,109,.04),0px 6px 20px -4px rgba(64,87,109,.3)",
                  width: "calc(var(--sidebar-not-main-width) - 0px)",
                  backgroundColor: "var(--sidebar-not-main-background-color)",
                  margin: "0px 0px 40px 0px !important",
                  padding: "0px 0px 0px 10px !important",
                  overflow: "hidden",
                  position: "fixed",
                  height: isMobile
                    ? "calc(calc(100% - calc(var(--mobile-bottom-navigation-height) + 56px)) - 1px)"
                    : "calc(100% - 64px)",
                  zIndex: 5,
                  left: "calc(var(--sidebar-toolbar-not-main-width) + 6px)",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {drawerBasicNotMainSecondaryList({
                  id: actualPageId,
                })?.secondaryList?.find(
                  (item) => item.id === drawerSecondaryListSelected
                )?.content &&
                  drawerBasicNotMainSecondaryList({
                    id: actualPageId,
                  })?.secondaryList?.find(
                    (item) => item.id === drawerSecondaryListSelected
                  )?.content}
              </Container>
            </Container>
          </Container>
        )}
      </Container>
    </>
  );
}
export default DoliSidebarNavigationNotMain;