import React from "react";
import Container from "@mui/material/Container";
import { HoverTransitionStyle } from "./FullPageStyle";

function DoliCard({
  height = "auto",
  width = "auto",
  backgroundColorHoverProps,
  primary = false,
  onClick,
  children,
}) {
  return (
    <Container
      onClick={onClick}
      sx={{
        height: height,
        width: width,
        margin: "0px !important",
        padding: "8px !important",
        display: "flex",
        alignItems: "center",
        borderRadius: "var(--button-border-radius)",
        cursor: "pointer",
        ...HoverTransitionStyle,
        "&:hover": {
          backgroundColor: backgroundColorHoverProps
            ? backgroundColorHoverProps
            : primary
            ? "var(--purple-color-hover)"
            : "var(--white-button-hover-color)",
        },
      }}
    >
      {children}
    </Container>
  );
}
export default DoliCard;