import React from "react";
import AddItemInputContainer from "../../common/AddItemInputContainer";
import CustomListButton from "../../common/CustomListButton";
import ColorPickerPop from "../../common/ColorPickerPop";
import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PersonalizeName } from "./PersonalizeText";
import { handlePersonalizeSliderChange } from "../Personalize";
import UploadFolderButton from "../../common/UploadFolder/UploadFolderButton";
import { backgroundList } from "../../../backgroundList";
import { PersonalizeContext } from "../../Context/PersonalizeContext";
import DoliNotMainSidebarDetailContainer from "../../common/DoliNotMainSidebarDetailContainer";
import DoliSlider from "../../common/DoliSlider";

function PersonalizePage() {
  const { t } = useTranslation("global");
  const {
    backgroundColor,
    setBackgroundColor,
    backgroundImage,
    setBackgroundImage,
    backgroundImageOpacity,
    setBackgroundImageOpacity,
    buttonsColor,
    setButtonsColor,
    iconsColor,
    setIconsColor,
    linesColor,
    setLinesColor,
    menuButtonSize,
    setMenuButtonSize,
    menuButtonAlign,
    setMenuButtonAlign,
    menuButtonContentAlign,
    setMenuButtonContentAlign,
    ourBackgroundImage,
    setOurBackgroundImage,
  } = React.useContext(PersonalizeContext);
  const handleMenuButtonSize = (size) => {
    setMenuButtonSize(size);
  };

  const handleMenuButtonAlign = (align) => {
    setMenuButtonAlign(align);
  };

  const handleMenuButtonContentAlign = (align) => {
    setMenuButtonContentAlign(align);
  };

  return (
    <DoliNotMainSidebarDetailContainer
      title={t("sidebarPersonnalizePageDetailsTitle")}
    >
      <AddItemInputContainer
        titleFontSize={15}
        name={t("personalizeBackgroundColor")}
        notTextField={true}
      >
        <ColorPickerPop setColor={setBackgroundColor} color={backgroundColor} />
      </AddItemInputContainer>
      <AddItemInputContainer
        name={t("personalizeBackgroundImage")}
        titleFontSize={15}
        notTextField={true}
      >
        <UploadFolderButton
          showCategory={false}
          customImage={
            backgroundImage && backgroundImage.image !== null
              ? backgroundImage
              : { image: backgroundImage }
          }
          setCustomImage={setBackgroundImage}
          setImage={(e) => {
            if (e?.name) {
              console.log(e);
              setOurBackgroundImage(e.name);
            }
          }}
          image={backgroundList.find((img) => img.name === ourBackgroundImage)}
          imageListToUse={backgroundList}
        />
      </AddItemInputContainer>
      <AddItemInputContainer
        titleFontSize={15}
        widthName="100%"
        name={
          <PersonalizeName
            name={t("personalizeBackgroundImageOpacity")}
            value={backgroundImageOpacity * 100}
            unit={"%"}
          />
        }
        notTextField={true}
      >
        <DoliSlider
          value={backgroundImageOpacity}
          onChange={(e, value) =>
            handlePersonalizeSliderChange(e, value, setBackgroundImageOpacity)
          }
          step={0.1}
          max={1}
        />
      </AddItemInputContainer>
      <AddItemInputContainer
        titleFontSize={15}
        name={t("personalizeButtonsColors")}
        notTextField={true}
      >
        <ColorPickerPop setColor={setButtonsColor} color={buttonsColor} />
      </AddItemInputContainer>
      <AddItemInputContainer
        titleFontSize={15}
        name={t("personalizeIconsColors")}
        notTextField={true}
      >
        <ColorPickerPop setColor={setIconsColor} color={iconsColor} />
      </AddItemInputContainer>
      <AddItemInputContainer
        titleFontSize={15}
        name={t("personalizeLinesColors")}
        notTextField={true}
      >
        <ColorPickerPop setColor={setLinesColor} color={linesColor} />
      </AddItemInputContainer>
      <AddItemInputContainer
        titleFontSize={15}
        name={t("personalizeMenuButtonSize")}
        notTextField={true}
        style={{ boxSixing: "content-box !important" }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "row",
            margin: "6px 0px 0px 0px !important",
            justifyContent: "space-between",
            width: "100%",
            boxSizing: "content-box !important",
            marginTop: "6px",
          }}
        >
          <CustomListButton
            text={t("personalizeMenuButtonSizeConent")}
            primary={menuButtonSize === "CONTENT"}
            onClick={() => handleMenuButtonSize("CONTENT")}
            minWidth="30%"
            styles={{
              whiteSpace: "nowrap",
            }}
          />
          <CustomListButton
            text={t("personalizeMenuButtonSizeMedium")}
            primary={menuButtonSize === "MEDIUM"}
            onClick={() => handleMenuButtonSize("MEDIUM")}
            minWidth="30%"
          />

          <CustomListButton
            text={t("personalizeMenuButtonSizeLarge")}
            primary={menuButtonSize === "LARGE"}
            onClick={() => handleMenuButtonSize("LARGE")}
            minWidth="30%"
          />
        </Container>
      </AddItemInputContainer>
      {menuButtonSize !== "LARGE" && (
        <AddItemInputContainer
          titleFontSize={15}
          name={t("personalizeMenuButtonAlignment")}
          notTextField={true}
          style={{ boxSixing: "content-box !important" }}
        >
          <Container
            sx={{
              display: "flex",
              flexDirection: "row",
              margin: "6px 0px 0px 0px !important",
              justifyContent: "space-between",
              width: "100%",
              boxSizing: "content-box !important",
            }}
          >
            <CustomListButton
              text={t("personalizeMenuButtonAlignmentLeft")}
              primary={menuButtonAlign === "LEFT"}
              onClick={() => handleMenuButtonAlign("LEFT")}
              minWidth="30%"
            />
            <CustomListButton
              text={t("personalizeMenuButtonAlignmentCenter")}
              primary={menuButtonAlign === "CENTER"}
              onClick={() => handleMenuButtonAlign("CENTER")}
              minWidth="30%"
            />
            <CustomListButton
              text={t("personalizeMenuButtonAlignmentRight")}
              primary={menuButtonAlign === "RIGHT"}
              onClick={() => handleMenuButtonAlign("RIGHT")}
              minWidth="30%"
            />
          </Container>
        </AddItemInputContainer>
      )}
      {menuButtonSize !== "CONTENT" && (
        <AddItemInputContainer
          titleFontSize={15}
          name={t("personalizeMenuButtonContentAlignment")}
          notTextField={true}
          style={{ boxSixing: "content-box !important" }}
        >
          <Container
            sx={{
              display: "flex",
              flexDirection: "row",
              margin: "6px 0px 0px 0px !important",
              justifyContent: "space-between",
              width: "100%",
              boxSizing: "content-box !important",
            }}
          >
            <CustomListButton
              text={t("personalizeMenuButtonAlignmentLeft")}
              primary={menuButtonContentAlign === "LEFT"}
              onClick={() => handleMenuButtonContentAlign("LEFT")}
              minWidth="30%"
            />
            <CustomListButton
              text={t("personalizeMenuButtonAlignmentCenter")}
              primary={menuButtonContentAlign === "CENTER"}
              onClick={() => handleMenuButtonContentAlign("CENTER")}
              minWidth="30%"
            />
            <CustomListButton
              text={t("personalizeMenuButtonAlignmentRight")}
              primary={menuButtonContentAlign === "RIGHT"}
              onClick={() => handleMenuButtonContentAlign("RIGHT")}
              minWidth="30%"
            />
          </Container>
        </AddItemInputContainer>
      )}
    </DoliNotMainSidebarDetailContainer>
  );
}

export default PersonalizePage;
