import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { UserContext } from "../Context/UserContext";
import { AuthContext } from "../Context/AuthContext";
import Avatar from "@mui/material/Avatar";
import TuneIcon from "@mui/icons-material/Tune";
import NotificationsNoneSharpIcon from "@mui/icons-material/NotificationsNoneSharp";
import DoliIcon from "../common/DoliIcon";
import DoliButton from "../common/DoliButton";
import DoliCard from "../common/DoliCard";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { useNavigate } from "react-router-dom";
import { DoliNavigationContext } from "../Context/DoliNavigationContext";
import UserDropdown from "../Navigation/UserDropdown";

function DoliTopBarNavigation({
  notMainPage = false,
  detailSidebarIsHidden = false,
}) {
  const navigate = useNavigate();
  const { currentMerchantName } = React.useContext(UserContext);
  const { extractFirebaseProfileInformations } = React.useContext(AuthContext);
  const { handleCloseDrawer } = React.useContext(DoliNavigationContext);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        backgroundColor: notMainPage ? "" : "transparent !important",
        backgroundColor: "var(--white-color)",
        position: "fixed",
        zIndex: 2,
        top: 0,
        width: {
          xs: "0px",
          sm: "calc(100% - var(--sidebar-toolbar-not-main-width)) !important",
          md:
            detailSidebarIsHidden === true
              ? "calc(100% - var(--sidebar-toolbar-not-main-width)) !important"
              : "calc(100% - calc(var(--sidebar-not-main-width) + var(--sidebar-toolbar-not-main-width))) !important",
        },
        maxWidth: {
          xs: "0px",
          sm: "calc(100% - var(--sidebar-toolbar-not-main-width)) !important",
          md:
            detailSidebarIsHidden === true
              ? "calc(100% - var(--sidebar-toolbar-not-main-width)) !important"
              : "calc(100% - calc(var(--sidebar-not-main-width) + var(--sidebar-toolbar-not-main-width))) !important",
        },
        minWidth: {
          xs: "0px",
          sm: "calc(100% - var(--sidebar-toolbar-not-main-width)) !important",
          md:
            detailSidebarIsHidden === true
              ? "calc(100% - var(--sidebar-toolbar-not-main-width)) !important"
              : "calc(100% - calc(var(--sidebar-not-main-width) + var(--sidebar-toolbar-not-main-width))) !important",
        },
        right: 0,
      }}
      onClick={() => handleCloseDrawer()}
      onMouseDown={() => handleCloseDrawer()}
    >
      <Toolbar
        disableGutters
        sx={{
          backgroundColor: "transparent !important",
          display: "flex",
          width: "100%",
        }}
      >
        <DoliButton
          onClick={() => navigate("/user/billing?backPage=/user/dashboard")}
          iconBefore={
            <ArrowCircleUpIcon
              sx={{
                fontSize: "var(--icon-size)",
                color: "var(--white-color)",
                marginRight: "8px",
              }}
            />
          }
          minWidth="185px"
          width="185px"
          height={"38px"}
          styles={{
            whiteSpace: "nowrap",
            marginRight: "auto !important",
          }}
          text={"Changer de Forfait"}
        />
        <Container
          sx={{
            margin: "0px !important",
            padding: "0px !important",
            display: "flex",
            flexDirection: "row",
            width: "auto",
          }}
        >
          <DoliIcon onClick={() => navigate("/user/settings")}>
            <TuneIcon />
          </DoliIcon>
          <DoliIcon>
            <NotificationsNoneSharpIcon />
          </DoliIcon>
          <DoliCard onClick={(event) => handleOpenUserMenu(event)}>
            <Container
              sx={{
                margin: "0px !important",
                padding: "0px !important",
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                alignItems: "center",
              }}
            >
              {extractFirebaseProfileInformations()?.photoURL ? (
                <div
                  alt="profile"
                  style={{
                    height: 40,
                    width: 40,
                    borderRadius: 100,
                    background: `url(${
                      extractFirebaseProfileInformations()?.photoURL
                    }`,
                    backgroundSize: 40,
                  }}
                />
              ) : (
                <Avatar
                  sx={{
                    width: "40px",
                    height: "40px",
                  }}
                  alt={extractFirebaseProfileInformations()?.firstName?.toUpperCase()}
                  src="/static/images/avatar/2.jpg"
                />
              )}
              <div>
                <div
                  style={{
                    fontWeight: "400",
                    fontSize: 15,
                  }}
                >
                  {extractFirebaseProfileInformations()?.firstName}
                </div>
                <div
                  style={{
                    fontWeight: "500",
                    fontSize: 15,
                    color: "var(--category-secondary-text-color)",
                  }}
                >
                  {currentMerchantName}
                </div>
              </div>
            </Container>
          </DoliCard>
        </Container>
      </Toolbar>
      <UserDropdown
        anchorElUser={anchorElUser}
        handleOpenUserMenu={handleOpenUserMenu}
        handleCloseUserMenu={handleCloseUserMenu}
      />
    </Container>
  );
}
export default DoliTopBarNavigation;