import React from "react";
import AddItemInputContainer from "../../common/AddItemInputContainer";
import { useTranslation } from "react-i18next";
import { InformationsContext } from "../../Context/InformationsContext";
import DoliNotMainSidebarDetailContainer from "../../common/DoliNotMainSidebarDetailContainer";

function Wifi() {
  const { t } = useTranslation("global");
  const { networkName, setNetworkName, password, setPassword } =
    React.useContext(InformationsContext);
  return (
    <DoliNotMainSidebarDetailContainer
      title={t("sidebarSettingsWifiDetailsTitle")}
    >
      <AddItemInputContainer
        setter={setNetworkName}
        value={networkName}
        name={t("settingsNetworkName")}
        titleFontSize={15}
      />
      <AddItemInputContainer
        setter={setPassword}
        value={password}
        name={t("textPassword")}
        titleFontSize={15}
      />

      {/**<div
        style={{
          marginTop: 20,
          display: "flex",
          justifyContent: "flex-start",
          padding: "12px 24px",
          backgroundColor: "var(--bottom-background-color)",
          borderEndEndRadius: 8,
          borderEndStartRadius: 8,
        }}
      >
        <CustomListButton
          text={t("textSave")}
          big={true}
          type={"submit"}
          alignButtonLeft={true}
          onClick={() => {
            updateSettings({
              wifi: {
                networkName,
                password,
              },
            });
          }}
        />*/}
    </DoliNotMainSidebarDetailContainer>
  );
}

export default Wifi;
