import * as React from "react";
import "../../index.css";
import { Container } from "@mui/material";
import { FullPageSxStyle } from "./FullPageStyle";

export default function DoliHeaderCard({ children }) {
  return (
    <Container
      sx={{
        background: "rgb(139,61,255)",
        background:
          "linear-gradient(90deg, rgba(139,61,255,1) 0%, rgba(79,0,255,1) 49%, rgba(0,212,255,1) 100%)",
        borderRadius: "var(--header-card-background-border-radius)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        padding: "40px 20px !important",
        ...FullPageSxStyle,
      }}
    >
      {children}
    </Container>
  );
}
