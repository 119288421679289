import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import ProductsList from "./components/ProductsList/ProductsList";
import { CategoriesProvider } from "./components/Context/CategoriesContext";
import MenusList from "./components/MenusList/MenusList";
import { MenusProvider } from "./components/Context/MenusContext";
import Settings from "./components/Settings/Settings";
import { SettingsProvider } from "./components/Context/SettingsContext";
import Members from "./components/Members/Members";
import Plans from "./components/Plan/Plans";
import Billing from "./components/Billing/Billing";
import PublicRoute from "./routes/guards/PublicRoute";
import PrivateRoute from "./routes/guards/PrivateRoute";
import { AuthContext } from "./components/Context/AuthContext";
import Profile from "./components/Profile/Profile";
import QRCode from "./components/QRCode/QRCode";
import Login from "./components/Login/Login";
import ForgotPassword from "./components/Login/ForgotPassword";
import Register from "./components/Login/Register";
import Onboarding from "./components/Onboarding/Onboarding";
import AddNewRestaurant from "./components/AddNewRestaurant/AddNewRestaurant";
import Loading from "./components/Loading/Loading";
import Stats from "./components/Stats/Stats";
import Personalize from "./components/Personalize/Personalize";
import BottomNavigationV2 from "./components/Navigation/BottomNavigationV2";
import DoliNavigation from "./components/Navigation/DoliNavigation";
import { DoliNavigationProvider } from "./components/Context/DoliNavigationContext";
import { Container } from "@mui/material";
import DoliPageLayout from "./components/common/DoliPageLayout";
import { PersonalizeProvider } from "./components/Context/PersonalizeContext";
import { InformationsProvider } from "./components/Context/InformationsContext";
import { QRCodeProvider } from "./components/Context/QRCodeContext";

export const useTitle = (title) => {
  React.useEffect(() => {
    const oldTitle = document.title;
    title && (document.title = title);
    return () => (document.title = oldTitle);
  }, [title]);
};

const App = () => {
  const { loading } = useContext(AuthContext);

  if (loading) {
    return <Loading />;
  }

  return (
    <SettingsProvider>
      <CategoriesProvider>
        <MenusProvider>
          <Router>
            <DoliNavigationProvider>
              <PersonalizeProvider>
                <QRCodeProvider>
                  <InformationsProvider>
                    <DoliNavigation />
                    <Container
                      sx={{
                        position: "absolute",
                        background: {
                          xs: "linear-gradient(to bottom, hsla(0,0%,100%,0),#fff),linear-gradient(to left,#ebe0fc 45%,#ddeaf9)",
                          sm: "var(--white-color)",
                          md: "var(--white-color)",
                        },
                        top: 0,
                        height: "200px",
                        width: "100%",
                        margin: "0px !important",
                        padding: "0px !important",
                      }}
                    />
                    <DoliPageLayout>
                      <Routes>
                        <Route
                          path="/user/dashboard"
                          element={
                            <PrivateRoute
                              element={
                                <>
                                  <MenusList />
                                  <BottomNavigationV2 />
                                </>
                              }
                            />
                          }
                        />
                        <Route
                          path="/user/personalize"
                          element={
                            <PrivateRoute
                              element={
                                <>
                                  <Personalize />
                                  <BottomNavigationV2 />
                                </>
                              }
                            />
                          }
                        />
                        <Route
                          path="/user/menus/:menuId"
                          element={
                            <PrivateRoute
                              element={
                                <>
                                  {
                                    //<Navigation isBackButton={true} hideSettings={true} />
                                  }
                                  <ProductsList />
                                </>
                              }
                            />
                          }
                        />
                        <Route
                          path="/user/stats"
                          element={
                            <PrivateRoute
                              element={
                                <>
                                  {
                                    //<Navigation />
                                  }
                                  <Stats />
                                  <BottomNavigationV2 />
                                </>
                              }
                            />
                          }
                        />
                        <Route
                          path="/user/qrcode"
                          element={
                            <PrivateRoute
                              element={
                                <>
                                  {
                                    //<Navigation />
                                  }
                                  <QRCode />
                                  <BottomNavigationV2 />
                                </>
                              }
                            />
                          }
                        />
                        <Route
                          path="/user/settings"
                          element={
                            <PrivateRoute
                              element={
                                <>
                                  <Settings />
                                  <BottomNavigationV2 />
                                </>
                              }
                            />
                          }
                        />
                        <Route
                          path="/user/members"
                          element={
                            <PrivateRoute
                              element={
                                <>
                                  <Members />
                                  <BottomNavigationV2 />
                                </>
                              }
                            />
                          }
                        />
                        <Route
                          path="/user/plans"
                          element={
                            <PrivateRoute
                              element={
                                <>
                                  <Plans />
                                </>
                              }
                            />
                          }
                        />
                        <Route
                          path="/user/billing"
                          element={
                            <PrivateRoute
                              element={
                                <>
                                  <Billing />
                                </>
                              }
                            />
                          }
                        />
                        <Route
                          path="/user/profile"
                          element={
                            <PrivateRoute
                              element={
                                <>
                                  <Profile />
                                </>
                              }
                            />
                          }
                        />
                        <Route
                          path="/user/new-restaurant/:stepId"
                          element={
                            <PrivateRoute
                              element={
                                <>
                                  <AddNewRestaurant />
                                </>
                              }
                            />
                          }
                        />
                        <Route
                          path="/onboarding/:stepId"
                          element={
                            <PrivateRoute
                              element={
                                <>
                                  <Onboarding />
                                </>
                              }
                            />
                          }
                        />
                        <Route
                          path="/sign-in"
                          element={
                            <PublicRoute
                              element={
                                <>
                                  <Login />
                                </>
                              }
                            />
                          }
                        />
                        <Route
                          path="/forgot-password"
                          element={
                            <PublicRoute
                              element={
                                <>
                                  <ForgotPassword />
                                </>
                              }
                            />
                          }
                        />
                        <Route
                          path="/sign-up"
                          element={
                            <PublicRoute
                              element={
                                <>
                                  <Register />
                                </>
                              }
                            />
                          }
                        />
                        <Route
                          path="*"
                          element={<Navigate to="/user/dashboard" replace />}
                        />
                      </Routes>
                    </DoliPageLayout>
                  </InformationsProvider>
                </QRCodeProvider>
              </PersonalizeProvider>
            </DoliNavigationProvider>
          </Router>
        </MenusProvider>
      </CategoriesProvider>
    </SettingsProvider>
  );
};

export default App;