import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import DoliButton from "../common/DoliButton";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import HomeIcon from "@mui/icons-material/Home";
import SubscriptionLimit from "../SubscriptionLimit/SubscriptionLimit";
import DoliMobileDrawer from "../common/DoliMobileDrawer";
import DoliSidebarNavigationNotMain from "../common/DoliSidebarNavigationNotMain";
import { FullPageSxStyle } from "../common/FullPageStyle";

function DoliMobileNavigation({
  notMainPage = false,
  actualPageId = 0,
  hideDetailSidebar = false,
}) {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [changeTopBarBackground, setChangeTopBarBackground] =
    React.useState(false);
  const [showSubscriptionLimitModal, setShowSubscriptionLimitModal] =
    React.useState(false);

  const toggleDrawer = () => {
    console.log("[DoliMobileNavigation] toggleDrawer");
    setOpenDrawer(!openDrawer);
  };

  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 9) {
        setChangeTopBarBackground(true);
      } else {
        setChangeTopBarBackground(false);
      }
    });
    return () => {
      window.removeEventListener("scroll", () => {
        if (window.scrollY > 9) {
          setChangeTopBarBackground(true);
        } else {
          setChangeTopBarBackground(false);
        }
      });
    };
  }, []);

  return (
    <>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "0px !important",
          padding: "0px !important",
          ...FullPageSxStyle,
        }}
      >
        <Container
          sx={{
            backgroundColor: notMainPage
              ? ""
              : changeTopBarBackground
              ? "rgb(139,61,255)"
              : "transparent !important",
            background:
              notMainPage || changeTopBarBackground ? "rgb(139,61,255)" : "",
            background:
              notMainPage || changeTopBarBackground
                ? "var(--mobile-topbar-nav-background-color)"
                : "",
            position: "fixed",
            zIndex: 1000,
            top: 0,
          }}
        >
          <Toolbar
            disableGutters
            sx={{
              backgroundColor: "transparent !important",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
              }}
            >
              <Container
                sx={{
                  height: "auto",
                  width: "auto",
                  margin: "0px !important",
                  padding: "8px !important",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor:
                    notMainPage || changeTopBarBackground
                      ? "transparent"
                      : "var(--white-color)",
                  borderRadius: "var(--button-border-radius)",
                  cursor: "pointer",
                  ".MuiSvgIcon-fontSizeMedium": {
                    fontSize: "var(--icon-size) !important",
                    color:
                      notMainPage || changeTopBarBackground ? "white" : "black",
                  },
                }}
                onClick={() => toggleDrawer()}
              >
                <MenuIcon fontSize="medium" />
              </Container>
              {actualPageId !== 0 && notMainPage && (
                <Container
                  sx={{
                    height: "auto",
                    width: "auto !important",
                    padding: "8px !important",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "transparent",
                    borderRadius: "var(--button-border-radius)",
                    cursor: "pointer",
                    ".MuiSvgIcon-fontSizeMedium": {
                      fontSize: "var(--icon-size) !important",
                      color: "white",
                    },
                  }}
                  onClick={() => navigate("/user/dashboard")}
                >
                  <HomeIcon />
                </Container>
              )}
            </div>
            <DoliButton
              onClick={() => navigate("/user/billing?backPage=/user/dashboard")}
              iconBefore={
                <ArrowCircleUpIcon
                  style={{
                    fontSize: "var(--icon-size)",
                    color: "var(--white-color)",
                    marginRight: "8px",
                  }}
                />
              }
              backgroundColorProps={
                notMainPage || changeTopBarBackground ? "transparent" : ""
              }
              borderColorProps={
                notMainPage || changeTopBarBackground ? "white" : ""
              }
              backgroundColorHoverProps={
                notMainPage || changeTopBarBackground
                  ? "var(--white-button-hover-color-w-transparent-background)"
                  : "var(--purple-color-hover)"
              }
              height={"38px"}
              text={"Changer de Forfait"}
            />
          </Toolbar>
        </Container>
        {notMainPage && (
          <DoliSidebarNavigationNotMain
            actualPageId={actualPageId}
            hideDetailSidebar={hideDetailSidebar}
            isMobile={true}
          />
        )}
      </Container>
      <DoliMobileDrawer
        openDrawer={openDrawer}
        toggleDrawer={toggleDrawer}
        actualPageId={actualPageId}
      />
      {showSubscriptionLimitModal && (
        <SubscriptionLimit
          open={showSubscriptionLimitModal}
          closeModal={() => {
            setShowSubscriptionLimitModal(false);
            navigate("/user/dashboard");
          }}
        />
      )}
    </>
  );
}
export default DoliMobileNavigation;