import React from "react";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { Drawer } from "@mui/material";
import { UserContext } from "../Context/UserContext";
import Divider from "../common/Divider";
import { AuthContext } from "../Context/AuthContext";
import Avatar from "@mui/material/Avatar";
import RestaurantMenuSharpIcon from "@mui/icons-material/RestaurantMenuSharp";
import SettingsIcon from "@mui/icons-material/Settings";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import BarChartIcon from "@mui/icons-material/BarChart";
import { MenusContext } from "../Context/MenusContext";
import StorefrontIcon from "@mui/icons-material/Storefront";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DoliNavigationSquareCard from "../common/DoliNavigationSquareCard";
import SubscriptionLimit from "../SubscriptionLimit/SubscriptionLimit";
import DoliNavigationMainCard from "../common/DoliNavigationMainCard";
import UserDropdown from "../Navigation/UserDropdown";

function DoliMobileDrawer({
  actualPageId = 0,
  openDrawer,
  toggleDrawer,
  drawerWidth = "80vw",
}) {
  const navigate = useNavigate();
  const {
    currentMerchantSubscribed,
    currentMerchantName,
    doliQrUser,
    currentMerchantId,
    changeMerchant,
  } = React.useContext(UserContext);
  const { menus } = React.useContext(MenusContext);
  const { extractFirebaseProfileInformations } = React.useContext(AuthContext);
  const [showSubscriptionLimitModal, setShowSubscriptionLimitModal] =
    React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  React.useEffect(() => {
    console.log("sh");
  }, [openDrawer]);
  const handleMenuClick = (menuId) => {
    console.log("[DoliMobileNavigation] handleMenuClick");
    navigate(`/user/menus/${menuId}`);
  };

  const handleNavigationCardClick = (type) => {
    console.log("[DoliMobileNavigation] handleProfileButtonClick");
    if (type.isLink) {
      navigate(type.isLink);
    }
    if (type.onClick) {
      type.onClick();
    }
    if (!type.stayOpen) {
      toggleDrawer();
    }
  };

  const drawerMobileList = [
    {
      name: "Modifier mes Menus",
      icon: <RestaurantMenuSharpIcon fontSize="medium" />,
      link: "/user/dashboard",
      button: true,
      id: 0,
    },
    {
      name: "Informations de l'établissement",
      icon: <SettingsIcon fontSize="medium" />,
      link: "/user/settings",
      button: true,
      id: 1,
    },
    {
      name: "Statistiques",
      icon: <BarChartIcon fontSize="medium" />,
      link: "/user/stats",
      button: true,
      id: 2,
    },
    {
      name: "Votre QR code",
      icon: <QrCode2Icon fontSize="medium" />,
      link: "/user/qrcode",
      button: true,
      id: 3,
    },
    { spacer: true, hidden: menus.length === 0 },
    { name: "👇 Mes Menus", title: true, hidden: menus.length === 0 },
    {
      menuSection: true,
      contains: (
        <Container
          sx={{
            margin: "0px !important",
            padding: "0px var(--drawer-nav-padding) !important",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            gap: "10px",
          }}
        >
          {menus
            .sort((a, b) => a.order - b.order)
            .map((menu, menuIndex) => (
              <DoliNavigationSquareCard
                id={menu._id}
                onClick={() => {
                  if (currentMerchantSubscribed || menuIndex < 1) {
                    handleMenuClick(menu._id);
                    toggleDrawer();
                  } else {
                    setShowSubscriptionLimitModal(true);
                  }
                }}
                text={menu.name}
                iconProps={menu.icon}
              />
            ))}
        </Container>
      ),
    },
    { spacer: true },
    {
      button: true,
      icon: <AddIcon fontSize="medium" />,
      name: "Ajouter un restaurant",
      onClick: () => navigate("/user/new-restaurant/1"),
      id: 6,
    },
  ];

  return (
    <>
      <Drawer
        open={openDrawer}
        onClose={() => toggleDrawer()}
        sx={{
          ".MuiDrawer-paper": { width: drawerWidth },
        }}
      >
        <Container
          sx={{
            margin: "0px !important",
            padding: "0px !important",
          }}
        >
          <Container
            sx={{
              padding: "var(--drawer-padding) !important",
              fontWeight: "600",
              fontSize: "var(--drawer-title-font-size)",
            }}
          >
            Menu
          </Container>
          <Divider />
          <Container
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "0px !important",
              cursor: "pointer",
              //justifyContent: "space-between",
            }}
            onClick={(event) => handleOpenUserMenu(event)}
          >
            <Container
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "var(--drawer-nav-padding) !important",
              }}
            >
              {extractFirebaseProfileInformations()?.photoURL ? (
                <div
                  alt="profile"
                  style={{
                    height: 40,
                    width: 40,
                    borderRadius: 100,
                    background: `url(${
                      extractFirebaseProfileInformations()?.photoURL
                    }`,

                    marginRight: "10px",
                    backgroundSize: 40,
                  }}
                />
              ) : (
                <Avatar
                  sx={{
                    marginRight: "10px",
                  }}
                  alt={extractFirebaseProfileInformations()?.firstName?.toUpperCase()}
                  src="/static/images/avatar/2.jpg"
                />
              )}
              <div>
                <div
                  style={{
                    fontWeight: "400",
                    fontSize: 17,
                  }}
                >
                  {extractFirebaseProfileInformations()?.firstName}
                </div>
                <div
                  style={{
                    fontWeight: "500",
                    fontSize: 15,
                    color: "var(--category-secondary-text-color)",
                  }}
                >
                  {currentMerchantName}
                </div>
              </div>
            </Container>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                width: "60px",
                height: "60px",
              }}
            >
              <ArrowBackIosIcon
                sx={{
                  rotate: "270deg",
                  color: "var(--black-icon-color)",
                  fontSize: "20px",
                  ".MuiSvgIcon-root": {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                  },
                }}
              />
            </div>
          </Container>
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              margin: "0px !important",
              padding: "var(--drawer-nav-padding) !important",
              gap: "10px !important",
            }}
          >
            {drawerMobileList.map((item, index) => (
              <>
                {!item.hidden && (
                  <>
                    {item.title && (
                      <Container
                        sx={{
                          margin: "0px !important",
                          padding: "var(--drawer-nav-padding) !important",
                          fontSize: "var(--drawer-title-font-size)",
                        }}
                      >
                        {item.name}
                      </Container>
                    )}
                    {item.menuSection && item.contains}
                    {item.divider && (
                      <Divider
                        propsStyle={{
                          padding: "0px !important",
                          margin: "0px !important",
                        }}
                      />
                    )}
                    {item.spacer && <div style={{ height: 20 }} />}
                    {item.button && (
                      <DoliNavigationMainCard
                        id={item.id}
                        className={index}
                        key={index}
                        onClick={() => {
                          handleNavigationCardClick({
                            isLink: item.link,
                            onClick: item.onClick,
                            stayOpen: item.stayOpen,
                          });
                        }}
                        active={item.id === actualPageId}
                        text={item.name}
                        icon={item.icon}
                      />
                    )}
                  </>
                )}
              </>
            ))}
            {doliQrUser?.merchantsList?.map((merchant) => (
              <DoliNavigationMainCard
                id={merchant._id}
                className={merchant._id}
                onClick={() => {
                  changeMerchant(merchant._id);
                  navigate("/user/dashboard");
                  toggleDrawer();
                }}
                active={merchant._id === currentMerchantId}
                text={merchant.establishmentName}
                icon={<StorefrontIcon fontSize="medium" />}
              />
            ))}
          </Container>
        </Container>
      </Drawer>
      {showSubscriptionLimitModal && (
        <SubscriptionLimit
          open={showSubscriptionLimitModal}
          closeModal={() => {
            setShowSubscriptionLimitModal(false);
            navigate("/user/dashboard");
          }}
        />
      )}
      <UserDropdown
        anchorElUser={anchorElUser}
        handleOpenUserMenu={handleOpenUserMenu}
        handleCloseUserMenu={handleCloseUserMenu}
        handleCloseDrawer={toggleDrawer}
        isMobile={true}
      />
    </>
  );
}
export default DoliMobileDrawer;