import * as React from "react";
import "../../index.css";
import { Container } from "@mui/material";
import { HoverTransitionStyle } from "./FullPageStyle";

export default function DoliButton({
  onClick,
  text,
  primary = true,
  backgroundColorProps,
  backgroundColorHoverProps,
  borderColorProps,
  big = true,
  type,
  iconBefore,
  minWidth = "var(--sidebar-toolbar-not-main-width)",
  width = "auto",
  height,
  lineHeight,
  fontWeight = 500,
  styles,
  children,
}) {
  return (
    <Container
      onClick={() => onClick()}
      variant="outlined"
      sx={{
        color: primary ? "white" : "black",
        backgroundColor: backgroundColorProps
          ? backgroundColorProps
          : primary
          ? "var(--purple-color)"
          : "var(--create-product-secondary-button-color)",
        ...HoverTransitionStyle,
        "&:hover": {
          backgroundColor: backgroundColorHoverProps
            ? backgroundColorHoverProps
            : primary
            ? "var(--purple-color-hover)"
            : "var(--create-product-secondary-button-hover-color)",
          borderColor: borderColorProps
            ? borderColorProps
            : primary
            ? "var(--create-product-primary-button-border-color)"
            : "var(--create-product-secondary-button-border-color)",
        },
        textTransform: "none",
        border: "1px solid",
        borderColor: borderColorProps
          ? borderColorProps
          : primary
          ? "var(--purple-color)"
          : "var(--create-product-secondary-button-border-color)",
        minWidth: `${minWidth} !important`,
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
        display: "inline-flex",
        borderRadius: "var(--button-border-radius)",
        fontWeight: fontWeight ? fontWeight : big ? 500 : 500,
        padding: big ? "10px" : "0px !important",
        fontSize: big ? "15px" : "15px",
        width: width,
        height: height || "22px",
        lineHeight: lineHeight || "20px",
        margin: "0px !important",
        cursor: "pointer",
        ...styles,
      }}
      type={type}
    >
      {iconBefore && iconBefore}
      {text}
      {children && children}
    </Container>
  );
}
