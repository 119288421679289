import React from "react";
import Container from "@mui/material/Container";
import { DoliNavigationContext } from "../Context/DoliNavigationContext";

function DoliPageLayout({ children }) {
  const { handleCloseDrawer, navigationProps } = React.useContext(
    DoliNavigationContext
  );

  return (
    <Container
      sx={{
        width: navigationProps.nothing
          ? "100%"
          : {
              xs: navigationProps.mainPage ? "100%" : "calc(100% - 72px)",
              sm: "calc(100% - var(--sidebar-toolbar-not-main-width)) !important",
              md: navigationProps.hideDetailSidebar
                ? "calc(100% - var(--sidebar-toolbar-not-main-width)) !important"
                : "calc(100% - calc(var(--sidebar-not-main-width) + 65px)) !important",
            },
        maxWidth: navigationProps.nothing
          ? "100%"
          : {
              xs: navigationProps.mainPage ? "100%" : "calc(100% - 72px)",
              sm: "calc(100% - var(--sidebar-toolbar-not-main-width)) !important",
              md: navigationProps.hideDetailSidebar
                ? "calc(100% - var(--sidebar-toolbar-not-main-width)) !important"
                : "calc(100% - calc(var(--sidebar-not-main-width) + 65px)) !important",
            },
        minWidth: navigationProps.nothing
          ? "100%"
          : {
              xs: navigationProps.mainPage ? "100%" : "calc(100% - 72px)",
              sm: "calc(100% - var(--sidebar-toolbar-not-main-width)) !important",
              md: navigationProps.hideDetailSidebar
                ? "calc(100% - var(--sidebar-toolbar-not-main-width)) !important"
                : "calc(100% - calc(var(--sidebar-not-main-width) + 65px)) !important",
            },
        height: "100vh",
        padding: navigationProps.nothing
          ? "0px !important"
          : {
              xs: "var(--mobile-topbar-height) 0px 0px 0px !important",
              sm: "64px",
            },
        position: "absolute",
        right: 0,
      }}
      onClick={() => handleCloseDrawer()}
      onMouseDown={() => handleCloseDrawer()}
    >
      {children}
    </Container>
  );
}
export default DoliPageLayout;