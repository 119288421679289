import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FormatPaintIcon from "@mui/icons-material/FormatPaint";
import EditNoteIcon from "@mui/icons-material/EditNote";
import AddIcon from "@mui/icons-material/Add";
import PreviewIcon from "@mui/icons-material/Preview";
import QueueIcon from "@mui/icons-material/Queue";
import HomeIcon from "@mui/icons-material/Home";
import { QR_CODE_URL } from "../../consts";
import { UserContext } from "./UserContext";
import FormatSizeOutlinedIcon from "@mui/icons-material/FormatSizeOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import PanToolOutlinedIcon from "@mui/icons-material/PanToolOutlined";
import PersonalizePage from "../Personalize/Content/PersonalizePage";
import PersonalizeText from "../Personalize/Content/PersonalizeText";
import PersonalizeInformationModule from "../Personalize/Content/PersonalizeInformationsModule";
import PersonalizeReviewModule from "../Personalize/Content/PersonalizeReviewModule";
import PersonalizePromotional from "../Personalize/Content/PersonalizePromotionalText";
import PersonalizeAllergensText from "../Personalize/Content/PersonalizeAllergensText";
import ColorLensOutlinedIcon from "@mui/icons-material/ColorLensOutlined";
import WifiOutlinedIcon from "@mui/icons-material/WifiOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import BasicInformation from "../Settings/Content/BasicInformation";
import Appearance from "../Settings/Content/Apparence";
import Review from "../Settings/Content/Review";
import Wifi from "../Settings/Content/Wifi";
import SocialNetworks from "../Settings/Content/SocialNetworks";
import CropIcon from "@mui/icons-material/Crop";
import FrameContent from "../QRCode/Content/FrameContent";
import ColorsContent from "../QRCode/Content/ColorsContent";
import PersonIcon from "@mui/icons-material/Person";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

export const DoliNavigationContext = createContext();

// ENUMS
export const drawerBasicSecondaryListProps = {
  MENU: 0,
  INFORMATIONS: 1,
  QRCODE: 3,
  PROFILE: 4,
  PLANS: 5,
  PERSONNALIZE: 11,
  MENU_CREATION: 12,
};
export const drawerBasicSecondaryListActions = {
  EDIT_PROMOTIONAL_TEXT: "editPromotionalText",
  IMPORT_MENU: "importMenu",
  CREATE_MENU: "createMenu",
  ADD_NEW_DISH: "addDish",
  ADD_NEW_CATEGORY: "addCategory",
};

const getWindowDimensions = () => {
  return {
    width: window.innerWidth,
    height: window.screen.height,
  };
};

export const DoliNavigationProvider = ({ children }) => {
  const { t } = useTranslation("global");
  const navigate = useNavigate();
  const { currentMerchantId } = useContext(UserContext);
  const location = useLocation();
  const [navigationProps, setNavigationProps] = useState({
    mainPage: false,
    actualPageId: 0,
  });
  const [closeDrawer, setCloseDrawer] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [drawerAction, setDrawerAction] = useState(null);

  useEffect(() => {
    const handleResize = () => setWindowDimensions(getWindowDimensions());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleCloseDrawer = useCallback(() => {
    if (windowDimensions.width < 600) {
      if (!closeDrawer) {
        console.warn("[DoliNavigationProvider] handleCloseDrawer closeDrawer");
        setCloseDrawer(true);
      }
    } else if (
      windowDimensions.width < 900 &&
      windowDimensions.width > 600 &&
      !closeDrawer
    ) {
      setCloseDrawer(true);
    } else if (windowDimensions.width > 899 && closeDrawer) {
      setCloseDrawer(false);
    }
  }, [windowDimensions.width, closeDrawer]);

  const handleOpenDrawer = useCallback(() => {
    if (
      windowDimensions.width < 900 &&
      windowDimensions.width > 600 &&
      closeDrawer
    ) {
      setCloseDrawer(false);
    } else if (windowDimensions.width < 600) {
      if (!closeDrawer) {
        console.warn("[DoliNavigationProvider] handleOpenDrawer !closeDrawer");
        setCloseDrawer(false);
      } else if (closeDrawer) {
        console.warn("[DoliNavigationProvider] handleOpenDrawer closeDrawer");
        setCloseDrawer(false);
      }
    }
  }, [windowDimensions.width, closeDrawer]);

  React.useEffect(() => {
    handleCloseDrawer();
  }, [windowDimensions]);

  const navigationList = useMemo(
    () => [
      { link: "/user/dashboard", mainPage: true, nothing: false, id: 0 },
      { link: "/user/settings", mainPage: false, nothing: false, id: 1 },
      {
        link: "/user/stats",
        mainPage: true,
        id: 2,
        hideDetailSidebar: true,
        nothing: false,
      },
      { link: "/user/qrcode", mainPage: false, nothing: false, id: 3 },
      {
        link: "/user/profile",
        mainPage: false,
        nothing: false,
        hideDetailSidebar: true,
        id: 4,
      },
      {
        link: "/user/plans",
        mainPage: false,
        nothing: false,
        id: 5,
        hideDetailSidebar: true,
      },
      {
        link: "/user/members",
        mainPage: true,
        id: 6,
        hideDetailSidebar: true,
        nothing: false,
      },
      { link: "/user/billing", id: 10, nothing: true },
      { link: "/user/personalize", mainPage: false, nothing: false, id: 11 },
      { link: "/user/menus", mainPage: true, nothing: false, id: 12 },
      { link: "/user/new-restaurant", mainPage: true, nothing: true, id: 13 },
      { link: "/onboarding/", mainPage: true, nothing: true, id: 14 },
      { link: "/sign-in", mainPage: true, nothing: true, id: 15 },
      { link: "/forgot-password", mainPage: true, nothing: true, id: 16 },
      { link: "/sign-up", mainPage: true, nothing: true, id: 17 },
    ],
    []
  );

  const handleNavigation = useCallback(() => {
    const page = navigationList.find(
      (page) =>
        page.link === location.pathname || location.pathname.includes(page.link)
    );
    setDrawerSecondaryListSelected(0);
    if (page) {
      console.log("[DoliNavigationProvider] page", page);
      setNavigationProps({
        mainPage: page?.mainPage,
        actualPageId: page?.id,
        hideDetailSidebar: page?.hideDetailSidebar,
        nothing: page?.nothing ?? false,
      });
    } else {
      setNavigationProps({
        mainPage: false,
        actualPageId: 0,
        hideDetailSidebar: false,
        nothing: page?.nothing ?? false,
      });
    }
  }, [navigationList, location.pathname]);

  useEffect(() => {
    handleNavigation();
  }, [handleNavigation]);

  const handleDrawerAction = useCallback((action) => {
    setDrawerAction(action);
  }, []);

  const reinitializeDrawerAction = useCallback(() => {
    setDrawerAction(null);
  }, []);

  const drawerBasicSecondaryList = useCallback(
    (props) => {
      if (props.id === drawerBasicSecondaryListProps.MENU) {
        return {
          id: 0,
          secondaryList: [
            {
              button: true,
              title: "Retourner à l'accueil",
              icon: <HomeIcon />,
              link: "/user/dashboard",
            },
            {
              module: true,
              title: "✏️ Outils",
              container: [
                {
                  name: "Nouveau Menu",
                  icon: <AddIcon fontSize={"small"} />,
                  onClick: () =>
                    handleDrawerAction(
                      drawerBasicSecondaryListActions.CREATE_MENU
                    ),
                },
                {
                  name: "Personnaliser",
                  icon: <FormatPaintIcon fontSize={"small"} />,
                  link: "/user/personalize",
                },
                {
                  name: t("promotionalModalButton"),
                  icon: <EditNoteIcon fontSize={"small"} />,
                  onClick: () =>
                    handleDrawerAction(
                      drawerBasicSecondaryListActions.EDIT_PROMOTIONAL_TEXT
                    ),
                },
                {
                  name: "Importer un Menu",
                  icon: <QueueIcon fontSize={"small"} />,
                  onClick: () =>
                    handleDrawerAction(
                      drawerBasicSecondaryListActions.IMPORT_MENU
                    ),
                },
                {
                  name: "Aperçu",
                  icon: <PreviewIcon fontSize={"small"} />,
                  onClick: () => {
                    window.open(
                      `${QR_CODE_URL}/go/${currentMerchantId}`,
                      "_blank"
                    );
                  },
                },
              ],
            },
          ],
        };
      }
      if (props.id === drawerBasicSecondaryListProps.MENU_CREATION) {
        return {
          id: 12,
          secondaryList: [
            {
              button: true,
              title: "Retourner à l'accueil",
              icon: <HomeIcon />,
              link: "/user/dashboard",
            },
            {
              module: true,
              title: "✏️ Outils",
              container: [
                {
                  name: "Nouveau Plat",
                  icon: <EditNoteIcon fontSize={"small"} />,
                  onClick: () =>
                    handleDrawerAction(
                      drawerBasicSecondaryListActions.ADD_NEW_DISH
                    ),
                },
                {
                  name: "Nouvelle Catégorie",
                  icon: <EditNoteIcon fontSize={"small"} />,
                  onClick: () =>
                    handleDrawerAction(
                      drawerBasicSecondaryListActions.ADD_NEW_CATEGORY
                    ),
                },
              ],
            },
          ],
        };
      }
      return {};
    },
    [t, currentMerchantId, handleDrawerAction]
  );

  const [drawerSecondaryListSelected, setDrawerSecondaryListSelected] =
    useState(0);
  const drawerBasicNotMainSecondaryList = useCallback(
    (props) => {
      if (props.id === drawerBasicSecondaryListProps.INFORMATIONS) {
        return {
          id: 1,
          primaryList: [
            {
              title: t("sidebarSettingsBasicInformationsTitle"),
              icon: <InfoOutlinedIcon />,
              id: 0,
              onClick: () => setDrawerSecondaryListSelected(0),
            },
            {
              title: t("sidebarSettingsApparenceTitle"),
              icon: <ColorLensOutlinedIcon />,
              id: 1,
              onClick: () => setDrawerSecondaryListSelected(1),
            },
            {
              title: t("sidebarSettingsReviewTitle"),
              icon: <ChatOutlinedIcon />,
              id: 2,
              onClick: () => setDrawerSecondaryListSelected(2),
            },
            {
              title: t("sidebarSettingsWifiTitle"),
              icon: <WifiOutlinedIcon />,
              id: 3,
              onClick: () => setDrawerSecondaryListSelected(3),
            },
            {
              title: t("sidebarSettingsSocialNetworksTitle"),
              icon: <ShareOutlinedIcon />,
              id: 4,
              onClick: () => setDrawerSecondaryListSelected(4),
            },
          ],
          secondaryList: [
            {
              id: 0,
              content: <BasicInformation />,
            },
            {
              id: 1,
              content: <Appearance />,
            },
            {
              id: 2,
              content: <Review />,
            },
            {
              id: 3,
              content: <Wifi />,
            },
            {
              id: 4,
              content: <SocialNetworks />,
            },
          ],
        };
      }
      if (props.id === drawerBasicSecondaryListProps.PERSONNALIZE) {
        return {
          id: 11,
          primaryList: [
            {
              title: "Couleurs",
              icon: <FormatPaintIcon />,
              id: 0,
              onClick: () => setDrawerSecondaryListSelected(0),
            },
            {
              title: "Texte",
              icon: <FormatSizeOutlinedIcon />,
              id: 1,
              onClick: () => setDrawerSecondaryListSelected(1),
            },
            {
              title: "Infos",
              icon: <InfoOutlinedIcon />,
              id: 2,
              onClick: () => setDrawerSecondaryListSelected(2),
            },
            {
              title: "Avis",
              icon: <StarBorderIcon />,
              id: 3,
              onClick: () => setDrawerSecondaryListSelected(3),
            },
            {
              title: "Promo",
              icon: <InfoOutlinedIcon />,
              id: 4,
              onClick: () => setDrawerSecondaryListSelected(4),
            },
            {
              title: "Allergène",
              icon: <PanToolOutlinedIcon />,
              id: 5,
              onClick: () => setDrawerSecondaryListSelected(5),
            },
          ],
          secondaryList: [
            {
              id: 0,
              content: <PersonalizePage />,
            },
            {
              id: 1,
              content: <PersonalizeText />,
            },
            {
              id: 2,
              content: <PersonalizeInformationModule />,
            },
            {
              id: 3,
              content: <PersonalizeReviewModule />,
            },
            {
              id: 4,
              content: <PersonalizePromotional />,
            },
            {
              id: 5,
              content: <PersonalizeAllergensText />,
            },
          ],
        };
      }
      if (props.id === drawerBasicSecondaryListProps.QRCODE) {
        return {
          id: 3,
          primaryList: [
            {
              title: t("qrCodePageFrameTitle"),
              icon: <CropIcon />,
              id: 0,
              onClick: () => setDrawerSecondaryListSelected(0),
            },
            {
              title: t("qrCodePageColorsTitle"),
              icon: <FormatPaintIcon />,
              id: 1,
              onClick: () => setDrawerSecondaryListSelected(1),
            },
          ],
          secondaryList: [
            {
              id: 0,
              content: <FrameContent />,
            },
            {
              id: 1,
              content: <ColorsContent />,
            },
          ],
        };
      }
      if (props.id === drawerBasicSecondaryListProps.PROFILE) {
        return {
          id: 4,
          primaryList: [
            {
              title: t("sidebarProfileTitle"),
              icon: <PersonIcon />,
              id: 0,
              onClick: () => navigate("/user/profile"),
            },
            {
              title: t("sidebarBillingAndSubscriptionTitle"),
              icon: <ReceiptLongIcon />,
              id: 1,
              onClick: () => navigate("/user/plans"),
            },
          ],
        };
      }
      if (props.id === drawerBasicSecondaryListProps.PLANS) {
        return {
          id: 4,
          primaryList: [
            {
              title: t("sidebarProfileTitle"),
              icon: <PersonIcon />,
              id: 1,
              onClick: () => navigate("/user/profile"),
            },
            {
              title: t("sidebarBillingAndSubscriptionTitle"),
              icon: <ReceiptLongIcon />,
              id: 0,
              onClick: () => navigate("/user/plans"),
            },
          ],
        };
      }
      return {};
    },
    [handleDrawerAction]
  );

  const contextValue = useMemo(
    () => ({
      navigationProps,
      handleCloseDrawer,
      handleOpenDrawer,
      closeDrawer,
      drawerBasicSecondaryList,
      drawerAction,
      handleDrawerAction,
      reinitializeDrawerAction,
      drawerBasicNotMainSecondaryList,
      drawerSecondaryListSelected,
    }),
    [
      navigationProps,
      handleCloseDrawer,
      handleOpenDrawer,
      closeDrawer,
      drawerBasicSecondaryList,
      drawerAction,
      handleDrawerAction,
      reinitializeDrawerAction,
      drawerBasicNotMainSecondaryList,
      drawerSecondaryListSelected,
    ]
  );

  return (
    <DoliNavigationContext.Provider value={contextValue}>
      {children}
    </DoliNavigationContext.Provider>
  );
};