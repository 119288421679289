import React from "react";
import AddItemInputContainer from "../../common/AddItemInputContainer";
import ColorPickerPop from "../../common/ColorPickerPop";
import { useTranslation } from "react-i18next";
import { handlePersonalizeSliderChange } from "../Personalize";
import { PersonalizeName } from "./PersonalizeText";
import { PersonalizeContext } from "../../Context/PersonalizeContext";
import DoliNotMainSidebarDetailContainer from "../../common/DoliNotMainSidebarDetailContainer";
import DoliSlider from "../../common/DoliSlider";

function PersonalizeAllergensText() {
  const { t } = useTranslation("global");
  const {
    allergensTitleFontSize,
    setAllergensTitleFontSize,
    allergensTitleColor,
    setAllergensTitleColor,
    allergensTextFontSize,
    setAllergensTextFontSize,
    allergensTextColor,
    setAllergensTextColor,
  } = React.useContext(PersonalizeContext);
  return (
    <DoliNotMainSidebarDetailContainer
      title={t("sidebarPersonnalizeAllergensModuletDetailsTitle")}
    >
      <AddItemInputContainer
        titleFontSize={15}
        name={t("personalizeAllergensTitleColor")}
        notTextField={true}
      >
        <ColorPickerPop
          setColor={setAllergensTitleColor}
          color={allergensTitleColor}
        />
      </AddItemInputContainer>
      <AddItemInputContainer
        titleFontSize={15}
        name={t("personalizeAllergensTextColor")}
        notTextField={true}
      >
        <ColorPickerPop
          setColor={setAllergensTextColor}
          color={allergensTextColor}
        />
      </AddItemInputContainer>
      <AddItemInputContainer
        titleFontSize={15}
        widthName="100%"
        name={
          <PersonalizeName
            name={t("personalizeAllergensTitleFontSize")}
            value={allergensTitleFontSize}
          />
        }
        notTextField={true}
      >
        <DoliSlider
          value={allergensTitleFontSize}
          onChange={(e, value) =>
            handlePersonalizeSliderChange(e, value, setAllergensTitleFontSize)
          }
          max={100}
        />
      </AddItemInputContainer>
      <AddItemInputContainer
        titleFontSize={15}
        widthName="100%"
        name={
          <PersonalizeName
            name={t("personalizeAllergensTextFontSize")}
            value={allergensTextFontSize}
          />
        }
        notTextField={true}
      >
        <DoliSlider
          max={100}
          value={allergensTextFontSize}
          onChange={(e, value) =>
            handlePersonalizeSliderChange(e, value, setAllergensTextFontSize)
          }
        />
      </AddItemInputContainer>
    </DoliNotMainSidebarDetailContainer>
  );
}

export default PersonalizeAllergensText;
