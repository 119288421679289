import React from "react";
import AddItemInputContainer from "../../common/AddItemInputContainer";
import ColorPickerPop from "../../common/ColorPickerPop";
import { useTranslation } from "react-i18next";
import { PersonalizeContext } from "../../Context/PersonalizeContext";
import DoliNotMainSidebarDetailContainer from "../../common/DoliNotMainSidebarDetailContainer";

function PersonalizeReviewModule() {
  const { t } = useTranslation("global");
  const {
    setReviewSendButtonText,
    reviewButtonTextColor,
    setReviewButtonTextColor,
    reviewButtonColor,
    setReviewButtonColor,
    activeStarColor,
    setActiveStarColor,
    reviewButtonText,
    setReviewButtonText,
    reviewPageTitleText,
    setReviewPageTitleText,
    reviewFeedbackText,
    setReviewFeedbackText,
    reviewCloseButtonText,
    setReviewCloseButtonText,
    reviewSendButtonText,
  } = React.useContext(PersonalizeContext);

  return (
    <DoliNotMainSidebarDetailContainer
      title={t("sidebarPersonnalizeReviewModuletDetailsTitle")}
    >
      <AddItemInputContainer
        titleFontSize={15}
        name={t("personalizeReviewButtonColor")}
        notTextField={true}
      >
        <ColorPickerPop
          setColor={setReviewButtonColor}
          color={reviewButtonColor}
        />
      </AddItemInputContainer>
      <AddItemInputContainer
        titleFontSize={15}
        name={t("personalizeReviewButtonTextColor")}
        notTextField={true}
      >
        <ColorPickerPop
          setColor={setReviewButtonTextColor}
          color={reviewButtonTextColor}
        />
      </AddItemInputContainer>
      <AddItemInputContainer
        titleFontSize={15}
        name={t("personalizeActiveStarColor")}
        notTextField={true}
      >
        <ColorPickerPop setColor={setActiveStarColor} color={activeStarColor} />
      </AddItemInputContainer>
      <AddItemInputContainer
        titleFontSize={15}
        name={t("personalizeReviewButtonText")}
        setter={setReviewButtonText}
        value={reviewButtonText}
      />
      <AddItemInputContainer
        titleFontSize={15}
        name={t("personalizeReviewPageTitleText")}
        setter={setReviewPageTitleText}
        value={reviewPageTitleText}
      />
      <AddItemInputContainer
        titleFontSize={15}
        name={t("personalizeReviewFeedbackText")}
        setter={setReviewFeedbackText}
        value={reviewFeedbackText}
      />
      <AddItemInputContainer
        titleFontSize={15}
        name={t("personalizeReviewCloseButtonText")}
        setter={setReviewCloseButtonText}
        value={reviewCloseButtonText}
      />
      <AddItemInputContainer
        titleFontSize={15}
        name={t("personalizeReviewSendButtonText")}
        setter={setReviewSendButtonText}
        value={reviewSendButtonText}
      />
    </DoliNotMainSidebarDetailContainer>
  );
}

export default PersonalizeReviewModule;
